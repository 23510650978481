import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { ApiErrorCode, CreateSubscriptionReq } from 'cogamika-back/types';
import { Button, Flex, Text } from 'components/common';
import { AppConfig, paymentRoutes } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useStripeActions,
  useTrialPromoCodes,
} from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { errorNotification, getUserDetails } from 'slices';
import { formatDate, myAddDays } from 'utils';
import { __ } from 'utils/i18n';
import { RadioButton } from '../RadioInput';
import { StyledForm, StyledInput, StyledTextWrapper } from './styles';

export const AddCardStripeForm: FC = () => {
  const { priceIds, clientSecret, customerId, createSubscription } =
    useStripeActions();
  const { checkTrialPromoCode, isValidCode, trialPromo } = useTrialPromoCodes();
  const { user } = useAppSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const methods = useForm({
    mode: 'onSubmit',
  });

  const { watch, setError, clearErrors } = methods;
  const period = watch('period');

  const handleOnBlur = (value: string) => checkTrialPromoCode(value);

  const onSubmit = async () => {
    setIsProcessing(true);

    if (!stripe || !elements || !customerId) {
      setIsProcessing(false);
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
          },
        },
        return_url: `${process.env.REACT_APP_URL}${paymentRoutes.finished}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setIsProcessing(false);
      dispatch(errorNotification(ApiErrorCode.CannotConfirmStripeIntentSetup));
    } else {
      //@TODO It could be send throw our server
      const { setupIntent, error: setupIntentError } =
        await stripe.retrieveSetupIntent(clientSecret);

      if (setupIntentError) {
        setIsProcessing(false);
        dispatch(errorNotification(ApiErrorCode.CannotRetrievedSetupIntent));
        return;
      }

      const paymentMethodId = setupIntent.payment_method as string;

      let subscriptionReq: CreateSubscriptionReq = {
        paymentMethodId,
        customerId,
        priceId: period,
      };

      if (trialPromo && isValidCode) {
        subscriptionReq = {
          ...subscriptionReq,
          trialPromoCodeId: trialPromo.id,
        };
      }

      const subscriptionId = await createSubscription(subscriptionReq);

      if (!subscriptionId) {
        setIsProcessing(false);
        dispatch(
          errorNotification(ApiErrorCode.CannotCreateStripeSubscription)
        );
        return;
      }

      dispatch(getUserDetails());
      setIsProcessing(false);
      navigate(paymentRoutes.finished);
    }
  };

  useEffect(() => {
    if (isValidCode) {
      clearErrors('promoCode');
    } else {
      setError('promoCode', {
        type: 'onBlur',
        message: __('validation.wrongGiftCode'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidCode]);

  const trialDays = trialPromo ? 14 + trialPromo.value : 14;

  if (!priceIds.length) return null;

  return (
    <StyledForm submitHandler={onSubmit} methods={methods}>
      <Text text="views.payment.settlementPeriod" size="xs" weight="semibold" />

      <RadioButton
        name="period"
        value={priceIds[0]}
        label="views.payment.settlementPeriodOptions.month"
      />
      <RadioButton
        name="period"
        value={priceIds[1]}
        label="views.payment.settlementPeriodOptions.year"
      >
        <StyledTextWrapper>
          <Text
            text="views.payment.settlementPeriodOptions.year"
            size="xs"
            weight="normal"
          />
          <Text
            text="views.payment.settlementPeriodOptions.saving"
            size="xs"
            weight="semibold"
            color="violet"
          />
        </StyledTextWrapper>
      </RadioButton>
      <Text
        text="views.payment.endTrial"
        size="xs"
        weight="semibold"
        translationArgs={{
          date: formatDate(
            myAddDays(new Date(), trialDays),
            AppConfig.DATA_FORMATS.STANDARD_FORMAT
          ),
        }}
      />
      <Flex fullWidth columnDirection gap="sm">
        <StyledInput
          name="promoCode"
          placeholder="giftCode"
          fullWidth
          onBlurAction={handleOnBlur}
        />
      </Flex>
      <PaymentElement />
      <Button
        variant="primary"
        text="button.startSubscription"
        type="submit"
        fullWidth
        uppercase
        disabled={!stripe || isProcessing || !period}
      />
    </StyledForm>
  );
};
