import styled from 'styled-components';
import { gtMedia } from 'config';

export const FooterWrapper = styled('div')(
  ({ theme }) => `
  padding: 45px 24px;
  margin-top: auto;
  display: flex;
  width: 100%;
  min-height: 140px;
  align-items: center;
  background-color: ${theme.colors.primary};

  ${gtMedia('sm')} {
    padding: 45px 72px;
  }

  ${gtMedia('md')} {
    padding: 45px 96px;
  }

  ${gtMedia('lg')} {
    padding: 45px 144px;
  }

  ${gtMedia('xl')} {
    padding: 45px 288px;
  }
`
);
