import React from 'react';

import { Icon, Text } from 'components/common';

import { DomainContainer } from './styles';
import { useMediaQuery } from 'hooks';

interface Props {
  name: string;
  isDone: boolean;
  isCurrent: boolean;
}

export const Domain: React.FC<Props> = ({ name, isDone, isCurrent }) => {
  const { isGtMedium, isGtLarge } = useMediaQuery();

  return (
    <DomainContainer>
      {isDone ? (
        <Icon name={'secondary-check'} color={'secondary'} size="xs" />
      ) : (
        <Icon
          name={isCurrent ? 'small-check' : 'secondary-check'}
          color={isCurrent ? 'secondary' : 'teritaryGray'}
          size="xs"
        />
      )}

      {(isGtMedium || isCurrent) && (
        <Text
          text={name}
          noTranslate
          size={isGtLarge ? 'base' : 'xs'}
          weight="semibold"
          color={isDone || isCurrent ? 'primary' : 'teritaryGray'}
        />
      )}
    </DomainContainer>
  );
};
