import { yupResolver } from '@hookform/resolvers/yup';
import { UserPayReq } from 'cogamika-back/types';
import { Button, Flex, GridItem, Text } from 'components/common';
import { payForSubscriptionSchema } from 'config';
import { useAppDispatch } from 'hooks';
import { usePayment } from 'hooks/usePayment';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { clearErrorNotification } from 'slices';
import { __ } from 'utils/i18n';
import { StyledForm, StyledGridContainer, StyledInput } from './styles';

export const GiftCodeForm: FC = () => {
  const { checkGiftCode, promo, isValidCode, pay } = usePayment();
  const dispatch = useAppDispatch();
  const methods = useForm<UserPayReq>({
    mode: 'onSubmit',
    resolver: yupResolver(payForSubscriptionSchema),
  });
  const {
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const handleOnBlur = (value: string) => checkGiftCode(value);

  useEffect(() => {
    if (isValidCode) {
      clearErrors('promoCode');
    } else {
      setError('promoCode', {
        type: 'onBlur',
        message: __('validation.wrongGiftCode'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidCode]);

  const submit = async (data: UserPayReq) => {
    dispatch(clearErrorNotification());
    if (promo) {
      pay(promo.plan, data);
    }
  };

  return (
    <StyledForm submitHandler={submit} methods={methods}>
      <StyledGridContainer size={[1, 2]} gap="md">
        <GridItem position={[1, 1]}>
          <Flex fullWidth columnDirection gap="sm">
            <Text
              text="views.payment.giftCode.label"
              weight="semibold"
              size="xs"
            />
            <StyledInput
              name="promoCode"
              placeholder="giftCode"
              fullWidth
              onBlurAction={handleOnBlur}
            />
            {promo && (
              <Text
                weight="normal"
                text={`views.payment.giftCode.info.${promo.plan}`}
                size="xs"
              />
            )}
          </Flex>
        </GridItem>
        <GridItem position={[1, 2]}>
          <Flex
            fullWidth
            columnDirection
            gap="sm"
            alignItems="center"
            margin={['md', 'none', 'none', 'none']}
          >
            <Button
              text="button.startSubscription"
              variant="primary"
              formAction="submit"
              fullWidth
              disabled={!!errors?.promoCode || !promo}
              uppercase
            />
          </Flex>
        </GridItem>
      </StyledGridContainer>
    </StyledForm>
  );
};
