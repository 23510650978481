import React from 'react';
import { useCookies } from 'react-cookie';

import {
  CookieBox,
  Footer,
  GameNavigation,
  Navigation,
} from 'components/common';
import { ColorType } from 'types';

import { useAppSelector } from 'hooks';
import { StyledMainContainer, StyledMainLayout } from './styles';

interface Props {
  children: React.ReactNode;
  bgColor?: ColorType;
  fullHeight?: boolean;
  gameNavigation?: boolean;
  fullWidth?: boolean;
  showFooter?: boolean;
}

export const MainLayout: React.FC<Props> = ({
  children,
  bgColor = 'lightGray',
  fullHeight,
  gameNavigation,
  fullWidth,
  showFooter,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const [cookies] = useCookies();

  return (
    <StyledMainLayout bgColor={bgColor}>
      {gameNavigation ? <GameNavigation /> : <Navigation />}
      <StyledMainContainer fullWidth={fullWidth} fullHeight={fullHeight}>
        {children}
      </StyledMainContainer>
      {(!user || showFooter) && <Footer />}
      {!cookies?.cookieAcceptance && <CookieBox />}
    </StyledMainLayout>
  );
};
