import { ltMedia } from 'config';
import styled, { css } from 'styled-components';

interface Props {
  position?: number[];
}

const GridItemComponent = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-self: stretch;
  justify-self: stretch;
  ${({ position }) =>
    position &&
    css`
      grid-column-start: ${position[0]};
      grid-column-end: ${position[2] ? position[2] : position[0]};
      grid-row: ${position[1]};
    `}
  ${ltMedia('sm')} {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row: auto;
  }
`;

export { GridItemComponent };
