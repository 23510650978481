import { gtMedia } from 'config';
import styled from 'styled-components';

interface Props {
  level: number;
}

const getVariant = (level: number) => {
  switch (level) {
    case 1:
      return `
        grid-template-columns: repeat(5, 1fr);
      `;
    case 2:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 3:
      return `
        grid-template-columns: repeat(7, 1fr);
      `;
    case 4:
      return `
        grid-template-columns: repeat(6, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }
      `;
    case 5:
      return `
        grid-template-columns: repeat(7, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }
      `;
    case 6:
      return `
        grid-template-columns: repeat(7, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(9, 1fr);
        }
      `;
    case 7:
      return `
        grid-template-columns: repeat(6, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(9, 1fr);
        }
      `;
    case 8:
      return `
        grid-template-columns: repeat(5, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }

        ${gtMedia('xl')} {
          grid-template-columns: repeat(10, 1fr);
        }
      `;
    case 9:
      return `
        grid-template-columns: repeat(8, 1fr);

        ${gtMedia('xl')} {
          grid-template-columns: repeat(11, 1fr);
        }
      `;
    case 10:
      return `
        grid-template-columns: repeat(6, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }

        ${gtMedia('xl')} {
          grid-template-columns: repeat(12, 1fr);
        }
      `;
    default:
      return '';
  }
};

export const MosaicCardsContainer = styled.div<Props>(
  ({ theme: { spaces }, level }) => `
  display: grid;
  justify-content: center;
  align-items: center;
  padding: ${spaces['2xl']} 0;
  gap: ${spaces.xs};
  ${getVariant(level)};

  ${gtMedia('md')} {
    padding: 60px 0;
    gap: ${spaces.sm}
  }

  ${gtMedia('lg')} {
    padding: 72px 0;
    gap: ${spaces.base}
  }

  ${gtMedia('lg')} {
    padding: 96px 0;
  }
`
);
