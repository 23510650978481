import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { gtMedia } from 'config';

export const Container = styled.div`
  padding: 32px 24px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled(LazyLoadImage)(
  ({ theme }) => `
    height: 257px;
    width: 100%;
    max-width: 620px;
    background: none;
    object-fit: fill;

    ${gtMedia('md')} {
      height: 437px;
      width: 100%;
      max-width: 832px;
    }
  `
);

export const StyledAudio = styled.audio`
  width: 600px;
`;
