import { FC, ReactNode } from 'react';

import { useMediaQuery } from 'hooks';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
} from './styles';
import { Text } from '../..';

interface AccordionProps {
  isOpen: boolean;
  onClickHeader: () => void;
  children?: ReactNode;
  noTranslate?: boolean;
  header?: string;
}

export const Accordion: FC<AccordionProps> = ({
  children,
  isOpen,
  onClickHeader,
  noTranslate = false,
  header = '',
}) => {
  const { isBigScreen } = useMediaQuery();
  return (
    <AccordionContainer>
      <AccordionHeader isOpen={isOpen} onClick={onClickHeader}>
        <Text
          size={isBigScreen ? 'base' : 'xs'}
          text={header}
          noTranslate={noTranslate}
          weight="normal"
        />
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
};
