import { createContext, Dispatch, SetStateAction } from 'react';

type StripeActionsContextType = {
  priceIds: string[];
  clientSecret: string;
  customerId: string;
  setPriceIds: Dispatch<SetStateAction<string[]>>;
  setClientSecret: Dispatch<SetStateAction<string>>;
  setCustomerId: Dispatch<SetStateAction<string>>;
};

export const StripeActionsContext = createContext<StripeActionsContextType>({
  priceIds: [],
  clientSecret: '',
  customerId: '',
  setPriceIds: () => {},
  setClientSecret: () => {},
  setCustomerId: () => {},
});
