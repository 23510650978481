import styled from 'styled-components';

export const MainContainer = styled('div')(
  ({ theme: { colors, boxShadows } }) => `
  position: fixed;
  max-width: 85%;
  max-height: 90%;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${boxShadows.basic};
  display: flex;
  flex-direction: column;
`
);

export const Wrapper = styled('div')(
  ({ theme: { colors } }) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondaryBackground};

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 22, 24, 0.1);
  }
`
);

export const StyledVideo = styled.video`
  height: 275px;
`;

export const ButtonContainer = styled('div')(
  ({ theme: { colors } }) => `
  padding: 6px;
  background-color: ${colors.light};
  display: flex;
  justify-content: flex-end;
`
);
