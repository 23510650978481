import { MarginType, PaddingType, Spaces } from 'types';

export const getMargin = (margin: MarginType, spaces: Spaces) => {
  const marginTop = margin[0] ? spaces[margin[0]] : '0';
  const marginRight = margin[1] ? spaces[margin[1]] : '0';
  const marginBottom = margin[2] ? spaces[margin[2]] : '0';
  const marginLeft = margin[3] ? spaces[margin[3]] : '0';

  return `
    margin-top: ${marginTop};
    margin-right: ${marginRight};
    margin-bottom: ${marginBottom};
    margin-left: ${marginLeft};
  `;
};

export const getPadding = (padding: PaddingType, spaces: Spaces) => {
  const paddingTop = padding[0] ? spaces[padding[0]] : '0';
  const paddingRight = padding[1] ? spaces[padding[1]] : '0';
  const paddingBottom = padding[2] ? spaces[padding[2]] : '0';
  const paddingLeft = padding[3] ? spaces[padding[3]] : '0';

  return `
    padding-top: ${paddingTop};
    padding-right: ${paddingRight};
    padding-bottom: ${paddingBottom};
    padding-left: ${paddingLeft};
  `;
};
