import { SubscribePlans } from 'cogamika-back/types';

export enum ModalType {
  Instruction = 'instruction',
  Info = 'info',
  Confirmation = 'confirmation',
  DeleteFile = 'delete-file',
  BadgeInfo = 'badge-info',
  SessionUnavailableError = 'session-unavailable-error',
  SaveSessionError = 'save-session-error',
  SubscriptionExpiring = 'subscription-expiring',
  PaymentPackage = 'payment-package',
}
export type ShowModalActionPayload =
  | InstructionModalPayload
  | InfoModalPayload
  | ConfirmationModalPayload
  | DeleteFilePayload
  | BadgeInfoModalPayload
  | SessionUnavailableErrorModalPayload
  | SaveSessionErrorModalPayload
  | SubscriptionExpiringPayload
  | PaymentPackageModalPayload;

export type ModalState =
  | InitialModalState
  | InstructionModalState
  | InfoModalState
  | ConfirmationModalState
  | DeleteFileModalState
  | BadgeInfoModalState
  | SessionUnavailableErrorModalState
  | SaveSessionErrorModalState
  | SubscriptionExpiringModalState
  | PaymentPackageModalState;

export interface BaseModal {
  isOpen: boolean;
}

export type ModalData =
  | InstructionModalData
  | InfoModalData
  | ConfirmationModalData
  | DeleteFileModalData
  | BadgeInfoModalData
  | PaymentPackageModalData;

export interface InitialModalState extends BaseModal {
  type: ModalType | null;
  data: ModalData | null;
}

export interface InstructionModalData {
  instruction: string;
  instructionFileId: string | null;
}

export interface InstructionModalPayload {
  type: ModalType.Instruction;
  data: InstructionModalData;
}

export type InstructionModalState = BaseModal & InstructionModalPayload;

export interface InfoModalData {
  text: string;
}

export interface InfoModalPayload {
  type: ModalType.Info;
  data: InfoModalData;
}

export type InfoModalState = BaseModal & InfoModalPayload;

export interface SessionUnavailableErrorModalPayload {
  type: ModalType.SessionUnavailableError;
  data: null;
}

export type SessionUnavailableErrorModalState = BaseModal &
  SessionUnavailableErrorModalPayload;

export interface SaveSessionErrorModalPayload {
  type: ModalType.SaveSessionError;
  data: null;
}

export type SaveSessionErrorModalState = BaseModal &
  SaveSessionErrorModalPayload;

export type BadgeInfoModalData = {
  imgId: string;
  description: string;
  name: string;
};

export interface BadgeInfoModalPayload {
  type: ModalType.BadgeInfo;
  data: BadgeInfoModalData;
}

export type BadgeInfoModalState = BaseModal & BadgeInfoModalPayload;

export type PaymentPackageModalData = {
  subscriptionPlan: SubscribePlans;
  itemsAmount?: number;
  action?: () => void;
};

export interface PaymentPackageModalPayload {
  type: ModalType.PaymentPackage;
  data: PaymentPackageModalData;
}

export type PaymentPackageModalState = BaseModal & PaymentPackageModalPayload;

export type ConfirmationModalData = {
  action: {
    confirm: () => void;
    text: string;
    noTranslate?: boolean;
  };
  header?: string;
  text?: string;
  noTranslate?: boolean;
  showCloseButton?: boolean;
};

export interface ConfirmationModalPayload {
  type: ModalType.Confirmation;
  data: ConfirmationModalData;
}

export type ConfirmationModalState = BaseModal & ConfirmationModalPayload;

export interface DeleteFileModalData {
  fileId: string;
}

export interface DeleteFilePayload {
  type: ModalType.DeleteFile;
  data: DeleteFileModalData;
}

export type DeleteFileModalState = BaseModal & DeleteFilePayload;

export interface SubscriptionExpiringPayload {
  type: ModalType.SubscriptionExpiring;
  data: null;
}

export type SubscriptionExpiringModalState = BaseModal &
  SubscriptionExpiringPayload;
