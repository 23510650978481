import React, { ReactNode } from 'react';

import {
  Container,
  Wrapper,
  ImageContainer,
  ImageWrapper,
  Image,
} from './styles';

interface Props {
  children: ReactNode;
}

interface ImageProps {
  src: string;
}

export const ContentContainer: React.FC<Props> = ({ children }) => {
  return (
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export const ContentImageContainer: React.FC<Props> = ({ children }) => {
  return (
    <ImageContainer>
      <ImageWrapper>{children}</ImageWrapper>
    </ImageContainer>
  );
};

export const ContentImage: React.FC<ImageProps> = ({ src }) => {
  return <Image src={src} />;
};
