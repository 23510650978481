export const routes = {
  root: '/',
  freeGames: '/games',
  freeGame: '/games/:gameId',
  activateUser: '/register/activation/:userId/:token/',
  login: '/login',
  register: '/register',
  subscriptions: '/subscriptions',
  faq: '/faq',
  resetPassword: '/reset-password',
  setPassword: '/auth/set-password/:userId/:token/',
  registerConfirmation: '/register-confirmation',
};

export const superAdminRoutes = {
  root: '/super-admin',
  gameTest: '/super-admin/game-test/:gameId/:level',
  settings: '/super-admin/settings',
  dashboard: '/super-admin/dashboard',
  files: '/super-admin/files',
  newGame: '/super-admin/new-game',
  editGame: '/super-admin/edit-game/:id',
  editFreeGames: '/super-admin/edit-free-games',
};

export const adminRoutes = {
  root: '/admin',
  gameTest: '/admin/game-test/:gameId/:level',
  dashboard: '/admin/dashboard',
  settings: '/admin/settings',
  newGame: '/admin/new-game',
  editGame: '/admin/edit-game/:id',
  files: '/admin/files',
};

export const userRoutes = {
  root: '/user',
  dashboard: '/user/dashboard',
  session: '/user/session',
  games: '/user/games',
  singleGame: '/user/games/:gameId',
};

export const userNotPaidRoutes = {
  root: '/user',
  account: '/user/account',
  accountHelp: '/user/account/:help',
};

export const paymentRoutes = {
  root: '/payment',
  finished: '/payment/finished',
  getInByCode: '/payment/your-code',
  changeCard: '/payment/change-card',
  addCard: '/payment/add-card',
};
