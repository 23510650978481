import { Button } from 'components/common/Button';
import styled from 'styled-components';
import { ColorType } from 'types';

interface ButtonProps {
  color: ColorType;
}

export const SubscriptionPackageWrapper = styled('div')(
  ({ theme: { colors, boxShadows } }) => `
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${colors.secondaryBackground};
  padding: 30px 24px;
  max-width: 460px;
  box-shadow: ${boxShadows.basic};
  margin: 0 24px;
`
);

export const SubscriptionButton = styled(Button)<ButtonProps>(
  ({ color, theme }) => `
  background-color: ${theme.colors[color]};
`
);

export const SubscriptionContent = styled('div')(
  () => `
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  justify-content: space-between;
`
);

export const SubscriptionDescriptionList = styled('ul')(
  () => `
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 8px;
`
);

export const SubscriptionDescriptionListItem = styled('li')(
  () => `
`
);

export const SubscriptionCloseIconWrapper = styled('button')(
  () => `
  background: transparent;
  border: none;
  padding: 16px;
  position: absolute;
  top: 16px;
  right: 8px;
`
);
