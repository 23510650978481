import React from 'react';

import { Badge, Button, Flex, FlexAlignCenter, Text } from 'components/common';
import { userNotPaidRoutes, userRoutes } from 'config';
import { useAppDispatch, useAppSelector, useMediaQuery } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { clearSessionGames, setBadges } from 'slices';

import { TrainingEndedContainer } from './styles';
export const TrainingEnded: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isGtLarge } = useMediaQuery();
  const { badges } = useAppSelector((state) => state.gameController);

  const handleOnFinished = () => {
    dispatch(clearSessionGames());
    navigate(userRoutes.dashboard);
  };

  const handleOnSeeProgress = () => {
    dispatch(clearSessionGames());
    navigate(userNotPaidRoutes.account);
  };

  const closeBadges = () => dispatch(setBadges([]));

  return (
    <TrainingEndedContainer>
      {badges.length > 0 ? (
        <Flex columnDirection gap={isGtLarge ? 'lg' : 'base'} fullWidth>
          <Text
            text="views.game.obtainedBadge"
            size={isGtLarge ? 'md' : 'base'}
            weight="semibold"
          />
          <Flex columnDirection gap="lg" fullWidth>
            {badges.map(({ id, name, fileId, description }) => (
              <Badge
                key={id}
                direction="row"
                name={name}
                imgId={fileId || ''}
                description={description}
                canShowModal
                isAchieved
                showDescription
              />
            ))}
          </Flex>
          <Button
            fullWidth
            variant="secondary"
            text="button.next"
            uppercase
            onClick={closeBadges}
          />
        </Flex>
      ) : (
        <>
          <Flex columnDirection gap={isGtLarge ? 'sm' : 'xs'}>
            <Text
              text="views.game.trainingEndedHeading"
              size={isGtLarge ? 'md' : 'base'}
              weight="semibold"
            />
            <Text text="views.game.trainingEnded" />
          </Flex>
          <FlexAlignCenter fullWidth columnDirection={!isGtLarge} gap="sm">
            <Button
              variant="secondary"
              text="button.finish"
              uppercase
              onClick={handleOnFinished}
              dataCy="finish-session"
              fullWidth
            />
            <Button
              variant="outlined"
              text="button.seeProgress"
              uppercase
              onClick={handleOnSeeProgress}
              fullWidth
            />
          </FlexAlignCenter>
        </>
      )}
    </TrainingEndedContainer>
  );
};
