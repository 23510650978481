import styled from 'styled-components';

export const MainContainer = styled('div')(
  ({ theme: { colors, boxSizes, boxShadows } }) => `
  position: fixed;
  width: ${boxSizes.confirmModalBox};
  padding: 24px;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${boxShadows.basic};
`
);

export const Wrapper = styled('div')(
  ({ theme: { colors } }) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondaryBackground};

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 22, 24, 0.1);
  }
`
);
