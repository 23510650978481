import styled from 'styled-components';
import { gtMedia } from 'config';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${spaces.lg};
  `
);

export const Wrapper = styled.div(
  ({ theme: { spaces, layoutHorizontalSpaces } }) => `
    display: flex;
    flex-direction: column;
    gap: ${spaces.lg};

    ${gtMedia('md')} {
      margin-right: ${layoutHorizontalSpaces.sm};
    }

    ${gtMedia('lg')} {
      margin-right: ${layoutHorizontalSpaces.md};
    }

    ${gtMedia('lg')} {
      margin-right: ${layoutHorizontalSpaces.lg};
    }
  `
);

export const ImageContainer = styled.div`
  flex: 1;
  display: none;

  ${gtMedia('md')} {
    display: flex;
  }
`;

export const ImageWrapper = styled.div(
  ({ theme: { layoutVerticalSpaces, layoutHorizontalSpaces } }) => `


    ${gtMedia('md')} {
      margin: -${layoutVerticalSpaces.md} -${layoutHorizontalSpaces.md} -${
    layoutVerticalSpaces.md
  }
        0;
    }

    ${gtMedia('lg')} {
      margin: -${layoutVerticalSpaces.lg} -${layoutHorizontalSpaces.lg} -${
    layoutVerticalSpaces.lg
  }
        0;
    }

    ${gtMedia('xl')} {
      margin: -${layoutVerticalSpaces.xl} -${layoutHorizontalSpaces.xl} -${
    layoutVerticalSpaces.xl
  }
        0;
    }
  `
);

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
