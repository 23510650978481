import styled from 'styled-components';

import { gtMedia } from 'config';

interface Props {
  isFound?: boolean;
  isVisible?: boolean;
  isBoxShadow?: boolean;
}

export const StyledImage = styled.img<Props>(
  ({ theme: { radius }, isFound, isVisible, isBoxShadow }) => `
  border-radius: ${radius.md};
  display: ${isVisible ? 'block' : 'none'};
  cursor: pointer;
  ${isFound ? 'visibility: hidden;' : ''};
  ${isBoxShadow ? 'box-shadow: 0px 16px 32px -8px rgba(12, 12, 13, 0.4)' : ''};
  width: 84px;
  height: 84px;

  ${gtMedia('md')} {
    width: 96px;
    height: 96px;
  }

  ${gtMedia('lg')} {
    width: 132px;
    height: 132px;
  }
`
);
