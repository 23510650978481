import styled from 'styled-components';

export const BucketContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  justify-content: center;
  gap: ${spaces.lg};
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: ${spaces.lg};
`
);
