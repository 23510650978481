import { FC } from 'react';
import {
  Bar,
  BarContainer,
  BarNumberContainer,
  ProgressContainer,
} from './styles';
import { Heading, Text } from '../Text';

interface ProgressProps {
  progress: number;
  title: string;
  noTranslate?: boolean;
}

export const Progress: FC<ProgressProps> = ({
  progress,
  title,
  noTranslate,
}) => {
  return (
    <ProgressContainer>
      <Heading
        text={title}
        size="xs"
        weight="medium"
        noTranslate={noTranslate}
      />
      <BarContainer>
        <Bar progress={progress} />
        <BarNumberContainer>
          <Text text={`${progress}%`} noTranslate size="xs" textAlign="end" />
        </BarNumberContainer>
      </BarContainer>
    </ProgressContainer>
  );
};
