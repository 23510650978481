import { gtMedia } from 'config';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const BoxContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translate(-50%, -24px);
  border-radius: 20px;
  box-shadow: none;

  ${gtMedia('md')} {
    bottom: 84px;
    transform: translate(-50%, -84px);
  }

  ${gtMedia('lg')} {
    bottom: 128px;
    transform: translate(-50%, -128px);
  }

  ${gtMedia('xl')} {
    bottom: 104px;
    transform: translate(-50%, -104px);
  }
`;

export const BoxImage = styled(LazyLoadImage)(
  ({ theme: { radius } }) => `
    width: 100%;
    height: 100%;
    max-width: 132px;
    max-height: 132px;
    cursor: grab;
    object-fit: fill;
    transform: translate(0, 0);
    border-radius: ${radius.md};

    ${gtMedia('md')} {
      max-width: 144px;
      max-height: 144px;
    }

    ${gtMedia('lg')} {
      max-width: 200px;
      max-height: 200px;
    }
  `
);
