import React from 'react';
import SVG from 'react-inlinesvg';

import { ColorType, IconName, IconSizeType } from 'types';
import { colors } from 'config/theme/basicStyleValues/colors';
import { iconSizes } from 'config/theme/basicStyleValues/iconSizes';

import { StyledIconContainer } from './styles';

interface Props {
  name: IconName;
  color?: ColorType;
  size?: IconSizeType;
}

export const Icon: React.FC<Props> = ({ name, color, size }) => {
  return (
    <StyledIconContainer>
      {size ? (
        <SVG
          src={`/assets/images/icons/${name}.svg`}
          color={`${color && colors[color]}`}
          width={iconSizes[size]}
          height={iconSizes[size]}
        />
      ) : (
        <SVG
          src={`/assets/images/icons/${name}.svg`}
          color={`${color && colors[color]}`}
        />
      )}
    </StyledIconContainer>
  );
};
