import { FC } from 'react';

import { AccountSubPages, IconName } from 'types';

import {
  MobileNavWrapper,
  MobileNavItemText,
  MobileNavItemContainer,
  FakeDiv,
} from './styles';

export interface NavItem {
  text: string;
  icon: IconName;
  noTranslate?: boolean;
}

interface SidePanelNavProps {
  items: NavItem[];
  activeItem: number;
  onChangePage: (pageIndex: number) => void;
}

export const MobileNav: FC<SidePanelNavProps> = ({
  items,
  activeItem = AccountSubPages.Results,
  onChangePage,
}) => {
  const renderItems = items.map(({ text, noTranslate }, index) => (
    <MobileNavItemContainer
      active={activeItem === index}
      onClick={() => onChangePage(index)}
      key={index + text}
    >
      <MobileNavItemText
        text={text}
        noTranslate={noTranslate}
        size="2xs"
        active={activeItem === index}
      />
    </MobileNavItemContainer>
  ));

  return (
    <MobileNavWrapper>
      {renderItems}
      <FakeDiv />
    </MobileNavWrapper>
  );
};
