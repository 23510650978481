import styled from 'styled-components';
import { ColorType, SizeType } from 'types';

interface Props {
  color?: ColorType;
  bigBtn?: boolean;
  size?: SizeType;
}

export const StyledIconButton = styled.button<Props>(
  ({ theme: { colors, sizes }, color, size }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  cursor: pointer;
  font-weight: 600;
  border-style: none;
  color: ${color ? colors[color] : colors.primary};
  width: ${size ? sizes[size] : 'auto'};
  height: ${size ? sizes[size] : 'auto'};


  &:hover {
    filter: brightness(0.9);
  }
`
);
