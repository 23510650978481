import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import { gtMedia } from 'config';

export const StyledImage = styled(LazyLoadImage)`
  width: 620px;
  height: 270px;
  object-fit: fill;
  margin: 0 auto;
  display: flex;

  ${gtMedia('lg')} {
    width: 876px;
    height: 340px;
  }
`;
