import { FC } from 'react';
import { ModalType, SizeType } from 'types';
import { apiUrl } from 'config';
import { useModal } from 'hooks';
import {
  StyledBadgeContainer,
  StyledBadgeHeading,
  StyledBadgeHeadingPlaceholder,
  StyledBadgeText,
  StyledBadgeTextContainer,
  StyledBadgeTextPlaceholder,
  StyledImage,
  StyledImagePlaceholder,
} from './styles';

interface BadgeProps {
  size?: SizeType;
  direction?: 'column' | 'row';
  showDescription?: boolean;
  imgId: string;
  name: string;
  description?: string;
  isAchieved?: boolean;
  canShowModal?: boolean;
}

export const Badge: FC<BadgeProps> = ({
  direction,
  imgId,
  name,
  size,
  showDescription,
  description,
  canShowModal = false,
  isAchieved = false,
}) => {
  const { showModal } = useModal();

  const handleOnOpenModal = () => {
    if (canShowModal && isAchieved) {
      showModal({
        type: ModalType.BadgeInfo,
        data: {
          name,
          imgId,
          description: description || '',
        },
      });
    }
  };

  return (
    <StyledBadgeContainer
      direction={direction}
      size={size}
      onClick={handleOnOpenModal}
      showModal={canShowModal && isAchieved}
    >
      <StyledImage
        src={`${apiUrl}file/${imgId}`}
        size={size}
        isAchieved={isAchieved}
      />
      <StyledBadgeTextContainer>
        <StyledBadgeHeading
          text={name}
          size="xs"
          weight="bold"
          align={direction === 'row' ? 'left' : 'center'}
          noTranslate
          color={isAchieved ? 'primary' : 'secondaryGray'}
        />
        {showDescription && description && (
          <StyledBadgeText
            text={description}
            size="xs"
            textAlign={direction === 'column' ? 'center' : 'start'}
            noTranslate
            color={isAchieved ? 'primary' : 'secondaryGray'}
          />
        )}
      </StyledBadgeTextContainer>
    </StyledBadgeContainer>
  );
};

export const BadgePlaceholder: FC<
  Omit<BadgeProps, 'imgId' | 'isArchived' | 'name' | 'description'>
> = ({ direction, size, showDescription }) => {
  return (
    <StyledBadgeContainer direction={direction} size={size}>
      <StyledImagePlaceholder size={size} isAchieved />
      <StyledBadgeTextContainer>
        <StyledBadgeHeadingPlaceholder />
        {showDescription && <StyledBadgeTextPlaceholder />}
      </StyledBadgeTextContainer>
    </StyledBadgeContainer>
  );
};
