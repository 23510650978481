import styled from 'styled-components';

import { gtMedia } from 'config';

export const MainContainer = styled('div')(
  ({ theme: { colors, spaces, boxSizes, boxShadows } }) => `
    max-width: ${boxSizes.confirmModalBox};
    padding: ${spaces['lg']};
    background-color: ${colors.secondaryBackground};
    box-shadow: ${boxShadows.basic};

    ${gtMedia('lg')} {
      max-width: ${boxSizes.confirmModalBoxLarge};
      padding: ${spaces['6xl']};
    }
  `
);
