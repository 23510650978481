import React, { useState } from 'react';

import { apiUrl } from 'config';
import { Instruction } from 'types';
import { useMediaQuery } from 'hooks';
import { InstructionVideo } from 'components/games';
import {
  Flex,
  Text,
  Button,
  Heading,
  GridItem,
  GridContainer,
  IconButton,
} from 'components/common';

import {
  Wrapper,
  StyledVideo,
  MainContainer,
  DescriptionContainer,
} from './styles';

interface Props {
  fullInstruction: Instruction;
  onClose: () => void;
}

export const InstructionModal: React.FC<Props> = ({
  fullInstruction,
  onClose,
}) => {
  const { isGtMedium } = useMediaQuery();
  const { instruction, instructionFileId } = fullInstruction;
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

  const onShowVideoModal = () => setIsVideoModalOpen(true);
  const onCloseVideoModal = () => setIsVideoModalOpen(false);

  return (
    <Wrapper>
      <MainContainer>
        <GridContainer size={isGtMedium ? [2] : [1]} gap="7xl">
          {isGtMedium && (
            <GridItem position={[1]}>
              {instructionFileId && (
                <StyledVideo
                  controls
                  src={`${apiUrl}file/${instructionFileId}`}
                  disablePictureInPicture
                  autoPlay
                ></StyledVideo>
              )}
            </GridItem>
          )}
          <GridItem position={[2]}>
            <Flex columnDirection justifyContent="space-between" gap="md">
              <Flex columnDirection gap="md">
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  fullWidth
                >
                  <Heading
                    text="views.instruction.secondaryHeading"
                    size="base"
                    color="primary"
                    weight="semibold"
                  />
                  <IconButton name="cross" onClick={onClose} type="button" />
                </Flex>
                <DescriptionContainer>
                  <Text size="xs" text={instruction} noTranslate />
                </DescriptionContainer>
              </Flex>
              {!isGtMedium && (
                <Flex justifyContent="flex-end" fullWidth>
                  <Button
                    variant="show-video"
                    text="button.showVideo"
                    uppercase
                    onClick={onShowVideoModal}
                  />
                </Flex>
              )}
            </Flex>
          </GridItem>
        </GridContainer>

        {isVideoModalOpen && instructionFileId && (
          <InstructionVideo
            onClose={onCloseVideoModal}
            instructionFileId={instructionFileId}
          />
        )}
      </MainContainer>
    </Wrapper>
  );
};
