import styled from 'styled-components';

export const StyledSelect = styled.select(
  ({ theme: { radius, colors, fontSizes } }) => `
  padding: 0 30px 0 0px;
  border-radius: ${radius.md};
  background-color: ${colors.light};
  font-size: ${fontSizes.base};
  color: ${colors.primary};
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/assets/images/icons/dropdownLang.svg');
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
`
);

export const Option = styled.option`
  text-align: center;
`;
