import { gtMedia } from 'config';
import styled from 'styled-components';

export const AccordionWrapperContainer = styled('div')(
  () => `
  width: 100%;
  display: flex;
  flex-direction: column;
`
);

export const AccordionWrapperHeader = styled('div')(
  ({ theme }) => `
  display: flex;
  align-items: center;
  padding-bottom: ${theme.spaces.xs};
  gap: ${theme.spaces.md};
  border: none;
  background: transparent;

  ${gtMedia('lg')} {
    padding-bottom: ${theme.spaces.sm};
  }
`
);

export const AccordionWrapperContent = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
`
);
