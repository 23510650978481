import { FC } from 'react';
import { AppConfig } from 'config';

import { FooterWrapper } from './styles';
import { Flex } from 'components/common';
import { FooterLinkButton } from '../Button';
import Policy from '../../../files/policy.pdf';
import Rules from '../../../files/rules.pdf';

export const Footer: FC = () => {
  const handleOnClickCogamikaOrg = () =>
    window.location.replace(AppConfig.ABOUT_US_REDIRECT);

  const handleOnOpenFile = (path: string) => {
    const link = document.createElement('a');
    link.href = path;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  };

  return (
    <FooterWrapper>
      <Flex columnDirection alignItems="flex-start" gap="md">
        <FooterLinkButton
          size="xs"
          text="views.footer.policy"
          onClick={() => handleOnOpenFile(Policy)}
        />
        <FooterLinkButton
          size="xs"
          text="views.footer.rules"
          onClick={() => handleOnOpenFile(Rules)}
        />
        <FooterLinkButton
          size="xs"
          text="cogamika.org"
          noTranslate
          onClick={handleOnClickCogamikaOrg}
        />
        <FooterLinkButton
          size="xs"
          text="views.footer.contact"
          translationArgs={{ mail: 'info@cogamika.org' }}
        />
      </Flex>
    </FooterWrapper>
  );
};
