import React from 'react';
import { userNotPaidRoutes } from 'config';
const UserAccount = React.lazy(() => import('views/User/Account'));

export const UserNotPaidRoutes = [
  {
    path: userNotPaidRoutes.account,
    element: <UserAccount />,
  },
  {
    path: userNotPaidRoutes.accountHelp,
    element: <UserAccount />,
  },
];
