import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { ApiErrorCode } from 'cogamika-back/types';
import { Button } from 'components/common';
import { paymentRoutes, userNotPaidRoutes } from 'config';
import { useAppDispatch, useAppSelector, useStripeActions } from 'hooks';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { errorNotification } from 'slices';
import { StyledForm } from './styles';

export const ChangeCardStripeForm: FC = () => {
  const { priceIds, clientSecret, customerId, changeCard } = useStripeActions();
  const { user } = useAppSelector((state) => state.user);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const methods = useForm({
    mode: 'onSubmit',
  });

  const onSubmit = async () => {
    setIsProcessing(true);

    if (!stripe || !elements || !customerId) {
      setIsProcessing(false);
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
          },
        },
        return_url: `${process.env.REACT_APP_URL}${paymentRoutes.finished}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      setIsProcessing(false);
      dispatch(errorNotification(ApiErrorCode.CannotConfirmStripeIntentSetup));
    } else {
      //@TODO It could be send throw our server
      const { setupIntent, error: setupIntentError } =
        await stripe.retrieveSetupIntent(clientSecret);

      if (setupIntentError) {
        setIsProcessing(false);
        dispatch(errorNotification(ApiErrorCode.CannotRetrievedSetupIntent));
        return;
      }

      const paymentMethodId = setupIntent.payment_method as string;

      await changeCard({ customerId, paymentMethodId });

      setIsProcessing(false);
      navigate(userNotPaidRoutes.account);
    }
  };

  if (!priceIds.length) return null;

  return (
    <StyledForm submitHandler={onSubmit} methods={methods}>
      <PaymentElement />
      <Button
        variant="primary"
        text="button.change"
        type="submit"
        fullWidth
        uppercase
        disabled={!stripe || isProcessing}
      />
    </StyledForm>
  );
};
