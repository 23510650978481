import {
  SubscribePlans,
  SystemPaymentPackagesData,
  SystemPaymentStripePackagesData,
} from 'cogamika-back/types';
import { ColorType } from 'types';

export const subscriptionsData: SystemPaymentPackagesData = {
  MONTH: {
    price: 29.99,
    period: 1,
    itemInfoAmount: 4,
  },
  HALF_YEAR: {
    price: 149.99,
    period: 6,
    itemInfoAmount: 4,
  },
  YEAR: {
    price: 289.99,
    period: 12,
    itemInfoAmount: 4,
  },
};

export const subscriptionsStripeData: SystemPaymentStripePackagesData = {
  MONTH: {
    price: '29.90',
  },
  HALF_YEAR: {
    price: '29.90',
  },
  YEAR: {
    price: '249.90',
  },
};

export const subscriptionColor: { [key in SubscribePlans]: ColorType } = {
  MONTH: 'primary',
  HALF_YEAR: 'secondary',
  YEAR: 'orange',
};
