import { AccountData, UserAccountFullData } from './account';
import { SubscribePlans } from './payment';
import { Language } from '../translate';

export enum UserRole {
  Admin = 'ADMIN',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER',
  // additional roles for further development:
  // - user with multiple accounts
  // MultipliedUser = 'MULTIPLIED_USER',
}

export enum UserStatus {
  Waiting,
  Registered,
  Paid,
}

export interface UserBaseData {
  id: string;
  email: string;
  role: UserRole;
  status: UserStatus;
  firstName: string;
  lastName: string;
  currentTokenId: string | null;
  password: string;
  resetPasswordTokenId: string;
  registerTokenId: string;
  paidTo: Date;
  subscriptionPlan: SubscribePlans;
  createdAt: Date;
}
export interface UserFullData extends UserBaseData {
  accounts: UserAccountFullData[];
}

export type UserBaseDataWithoutCredentials = Omit<
  UserBaseData,
  | 'password'
  | 'currentTokenId'
  | 'resetPasswordTokenId'
  | 'registerTokenId'
  | 'paidTo'
  | 'createdAt'
> & {
  paidTo: string | null;
  lang: Language;
  isSubscriptionExpiring: boolean;
  isIntegrated: boolean;
  hasActiveMembership: boolean;
  hasConnectedCard: boolean;
  isPaused: boolean;
};

export type UserBaseDataWithoutCredentialsAndLang = Omit<
  UserBaseDataWithoutCredentials,
  'lang'
>;

export type UserBaseDataWithoutCredentialsWithAccounts =
  UserBaseDataWithoutCredentialsAndLang & { accounts: AccountData[] };

export type UserBaseDataForSelect = Pick<
  UserBaseDataWithoutCredentials,
  'id' | 'firstName' | 'lastName'
>;

export type UserRes = UserBaseDataWithoutCredentials & { nick: string };

export type GetUserListResponse = {
  user: UserBaseDataWithoutCredentials[];
};

export type UserDeleteRes = {
  id: string;
  deleted: boolean;
};
