import styled from 'styled-components';

interface Props {
  fullWidth?: boolean;
}

export const StyledInputContainer = styled.div<Props>`
  width: 100%;
`;

export const StyledInput = styled.input(
  ({ theme: { colors, fontSizes } }) => `
  display: flex;
  border: 1px solid ${colors.tertiaryBorder};
  padding: 14px 18px;
  border-radius: 10px;
  font-size: ${fontSizes.xs};
  width: 100%;
  ::placeholder {
    color: black;
  }
`
);

export const StyledLabel = styled.label(
  ({ theme: { fontSizes, fontWeight } }) => `
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeight.semibold};
  `
);

export const Wrapper = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.xs};
`
);
