import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { BasePaginationRes, FileRes, FileType } from 'cogamika-back/types';
import { useApi } from 'hooks';
import { Icon } from 'components/common';

import { SingleFile } from './SingleFile';

import {
  SearchInput,
  SelectContainer,
  InputContainer,
  IconContainer,
} from './styles';

interface Props {
  onSelectFile: (id: string, type?: FileType, originalName?: string) => void;
  refreshList: boolean;
  selectedIds: Set<string>;
  types?: FileType[];
}

export const FileSelector: React.FC<Props> = ({
  onSelectFile,
  refreshList,
  selectedIds,
  types,
}) => {
  const { get } = useApi();
  const [files, setFiles] = useState<FileRes[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>('');

  const getFiles = useCallback(
    async (currentPage: number, search: string) => {
      const res = await get<BasePaginationRes<FileRes>>('/file', {
        page: String(currentPage),
        limit: String(20),
        search: String(search),
        sortBy: 'originalName',
        sortDesc: 'ASC',
        ...(types && { type: [...types] }),
      });

      if (res && res.items.length > 0) {
        if (currentPage === 1) {
          setFiles(res.items);
          setHasMore(res.hasNextPage);
        } else {
          setFiles((prevFiles) => [...prevFiles, ...res.items]);
          setPage((prevPage) => prevPage + 1);
          setHasMore(res.hasNextPage);
        }
      } else {
        setPage(1);
        setFiles([]);
        setHasMore(false);
      }
    },
    [types, get]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    getFiles(1, e.target.value);
    setSearch(e.target.value);
  };

  useEffect(() => {
    getFiles(1, '');

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshList) {
      getFiles(1, search);
      setFiles([]);
      setPage(1);
    }

    // eslint-disable-next-line
  }, [refreshList]);

  return (
    <div>
      <InputContainer>
        <SearchInput onChange={handleSearch} autoFocus />
        <IconContainer>
          <Icon name="search" />
        </IconContainer>
      </InputContainer>
      <InfiniteScroll
        dataLength={files.length}
        next={() => getFiles(page + 1, search)}
        hasMore={hasMore}
        loader={''}
        height="455px" // TO DO przerobić na jakąs wartość
      >
        <SelectContainer>
          {files.map((file) => (
            <SingleFile
              key={file.id}
              file={file}
              onSelect={onSelectFile}
              isSelected={selectedIds.has(file.id)}
            />
          ))}
        </SelectContainer>
      </InfiniteScroll>
    </div>
  );
};
