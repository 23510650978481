import { FC } from 'react';

import { ColorType, IconName } from 'types';
import { Container } from './styles';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { useMediaQuery } from 'hooks';

interface MarkedInfoBoxProps {
  text: string;
  icon: IconName;
  color: ColorType;
  translationArgs?: Record<string, string | number>;
  fullWidth?: boolean;
}

export const MarkedInfoBox: FC<MarkedInfoBoxProps> = ({
  text,
  icon,
  color,
  translationArgs,
  fullWidth = false,
}) => {
  const { isBigScreen } = useMediaQuery();

  return (
    <Container color={color} fullWidth={fullWidth}>
      <Icon name={icon} size={isBigScreen ? 'md' : 'base'} />
      <Text
        text={text}
        color={color}
        translationArgs={translationArgs}
        size={isBigScreen ? 'base' : 'xs'}
        weight="semibold"
      />
    </Container>
  );
};
