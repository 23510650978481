import React from 'react';

import { useMediaQuery } from 'hooks';
import { Button, Flex, Icon, Text } from 'components/common';

import { ResultContainer } from '../styles';

interface Props {
  handleNext: () => void;
}

export const Success: React.FC<Props> = ({ handleNext }) => {
  const { isGtLarge } = useMediaQuery();

  return (
    <ResultContainer variant="success">
      <Flex alignItems="center" gap="base">
        <Icon name={!isGtLarge ? 'check-mobile' : 'check-desktop'} />
        <Text
          text="views.survey.correctAnswer"
          size={!isGtLarge ? 'xs' : 'base'}
          weight="semibold"
          color="darkGreen"
        />
      </Flex>
      <Button
        text="button.next"
        variant="outlined-success"
        uppercase
        iconName="right-arrow"
        onClick={handleNext}
        dataCy="nextQuestion"
      />
    </ResultContainer>
  );
};
