import update from 'immutability-helper';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { DndProvider, Preview } from 'react-dnd-multi-backend';
import React, { useCallback, useEffect, useState } from 'react';

import { Chronology } from 'games';
import { useMediaQuery } from 'hooks';
import { Text } from 'components/common';
import { apiUrl, AppConfig } from 'config';
import { ChronologyCardType } from 'types';
import { ElementType } from 'cogamika-back/types';

import { Card } from './Card';
import { ListContainer } from './styles';
import { StyledCard, StyledImage } from './Card/styles';

interface Props {
  chronology: Chronology;
  endGame: () => void;
}

export const Game: React.FC<Props> = ({ chronology, endGame }) => {
  const { isGtLarge } = useMediaQuery();
  const [cards, setCards] = useState<ChronologyCardType[]>(
    chronology.chronologyElements
  );
  const [isWin, setIsWin] = useState(false);

  const findCard = useCallback(
    (orderNumber: number) => {
      const card = cards.filter((c) => c.orderNumber === orderNumber)[0];
      return {
        card,
        index: cards.indexOf(card),
      };
    },
    [cards]
  );

  const moveCard = useCallback(
    (id: number, atIndex: number) => {
      const { card, index } = findCard(id);
      const updatedCards = update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });
      setCards(updatedCards);
    },
    [findCard, cards, setCards]
  );

  const dropCard = () => {
    chronology.checkWin(setIsWin, cards, setCards);
  };

  const generatePreviewCard = ({ style, itemType, item }: any) => {
    if (itemType === ElementType.Image) {
      return (
        <div style={{ ...style, opacity: 0.5 }}>
          <StyledImage
            isCorrect={false}
            src={`${apiUrl}file/${item.text}`}
            isWin={false}
            opacity={1}
            effect="opacity"
          />
        </div>
      );
    } else {
      return (
        <div style={{ ...style, opacity: 0.5 }}>
          <StyledCard isCorrect={false} isWin={isWin} opacity={1}>
            <Text
              text={item.text}
              size={!isGtLarge ? 'xs' : 'base'}
              weight="medium"
              noTranslate
              color={'primary'}
            />
          </StyledCard>
        </div>
      );
    }
  };

  useEffect(() => {
    if (isWin) {
      const timer = setTimeout(() => {
        endGame();
      }, AppConfig.DELAY_AFTER_WIN);

      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line
  }, [isWin]);

  return (
    <DndProvider options={HTML5toTouch}>
      <ListContainer>
        {cards.map((card) => (
          <Card
            isCorrect={card.isCorrect}
            elementType={chronology.settings.elementsType}
            key={card.orderNumber}
            id={card.orderNumber}
            text={card.description}
            moveCard={moveCard}
            dropCard={dropCard}
            findCard={findCard}
            isWin={isWin}
          />
        ))}
      </ListContainer>
      <Preview generator={generatePreviewCard} />
    </DndProvider>
  );
};
