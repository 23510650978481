import { Button, Icon } from 'components/common';
import { routes, subscriptionColor, subscriptionsData } from 'config';
import { useCurrency, useModal } from 'hooks';
import { FC, useEffect } from 'react';
import { PaymentPackageModalData } from 'types';
import { i18n } from 'utils/i18n';
import { Flex, Heading, Text } from '../../common';
import {
  SubscriptionButton,
  SubscriptionCloseIconWrapper,
  SubscriptionContent,
  SubscriptionDescriptionList,
  SubscriptionDescriptionListItem,
  SubscriptionPackageWrapper,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { SubscribePlans } from 'cogamika-back/types';

export const SubscriptionPackage: FC = () => {
  const { getData, closeModal } = useModal();
  const navigate = useNavigate();
  const {
    subscriptionPlan,
    action,
    itemsAmount = 0,
  } = getData<PaymentPackageModalData>();
  const [countedCurrency, countCurrency] = useCurrency();

  const onChooseSubscription = () => {
    closeModal();
    if (action) {
      action();
    }
  };

  const openRegisterModal = () => {
    closeModal();
    navigate(routes.register);
  };

  const openLoginModal = () => {
    closeModal();
    navigate(routes.login);
  };

  let description: string[] = [];

  for (let i = 0; i < itemsAmount; i++) {
    description.push(`views.payment.packages.${subscriptionPlan}.item${i}`);
  }

  useEffect(() => {
    countCurrency(subscriptionsData[subscriptionPlan].price, i18n.currentLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const descriptionItems = description.map((text, index) => (
    <SubscriptionDescriptionListItem key={text}>
      <Text
        size="xs"
        text={text}
        weight={
          index === 0 && subscriptionPlan !== SubscribePlans.Month
            ? 'bold'
            : 'normal'
        }
      />
    </SubscriptionDescriptionListItem>
  ));

  return (
    <SubscriptionPackageWrapper>
      <SubscriptionCloseIconWrapper onClick={() => closeModal()}>
        <Icon name="cross" />
      </SubscriptionCloseIconWrapper>
      <SubscriptionContent>
        <Flex columnDirection gap="xs" fullWidth>
          <Heading
            size="sm"
            text={`views.payment.packages.${subscriptionPlan}.name`}
            color="teritaryGray"
          />
          <Heading
            size="xl"
            text="views.payment.subcriptionPrice"
            translationArgs={{
              price: subscriptionsData[subscriptionPlan].price,
            }}
            color={subscriptionColor[subscriptionPlan]}
            weight="semibold"
          />
          {countedCurrency && (
            <Heading
              size="sm"
              text="views.payment.otherCurrency"
              translationArgs={{ priceInOtherCurrency: countedCurrency || '' }}
              color="teritaryGray"
            />
          )}
          <Text
            size="xs"
            textAlign="start"
            text={`views.payment.packages.${subscriptionPlan}.shortDescription`}
            color="primary"
            weight="normal"
          />
          <Flex gap="none" margin={['md', 'none', 'none', 'lg']}>
            <SubscriptionDescriptionList>
              {descriptionItems}
            </SubscriptionDescriptionList>
          </Flex>
        </Flex>
        {action ? (
          <Flex columnDirection fullWidth alignItems="center">
            <SubscriptionButton
              text="button.buyNow"
              variant="primary"
              fullWidth
              color={subscriptionColor[subscriptionPlan]}
              onClick={onChooseSubscription}
              uppercase
            />
          </Flex>
        ) : (
          <Flex
            columnDirection
            justifyContent="center"
            alignItems="center"
            fullWidth
            gap="sm"
          >
            <Text
              text="views.payment.selectPlan"
              weight="normal"
              textAlign="center"
            />
            <Button
              variant="primary"
              onClick={openLoginModal}
              text="button.subscriptionLogin"
              fullWidth
              uppercase
            />
            <Button
              variant="outlined"
              onClick={openRegisterModal}
              text="button.register"
              fullWidth
              uppercase
            />
          </Flex>
        )}
      </SubscriptionContent>
    </SubscriptionPackageWrapper>
  );
};
