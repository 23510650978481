import React from 'react';

import { Button } from 'components/common';

import { StyledNavigation } from './styles';
import { useMediaQuery } from 'hooks';

interface Props {
  onClose?: () => void;
  onOpenInstruction?: () => void;
}

export const GameNavigation: React.FC<Props> = ({
  onClose,
  onOpenInstruction,
}) => {
  const { isSmallScreen } = useMediaQuery();

  return (
    <StyledNavigation>
      <Button
        text="button.gameInstruction"
        variant="tertiary"
        uppercase
        onClick={onOpenInstruction}
        data-cy="show-rules"
        leftIcon={isSmallScreen ? 'book-mobile' : 'book-desktop'}
        padding={['', '', '', '']}
      />
      <Button
        text="button.leaveGame"
        variant="tertiary"
        onClick={onClose}
        data-cy="leave-game"
        uppercase
        padding={['', '', '', '']}
      />
    </StyledNavigation>
  );
};
