import { object, string, number } from 'yup';
import { checkPromoCode, optionalStringSchema } from 'utils';

export const payForSubscriptionSchema = object().shape({
  promoCode: checkPromoCode(),
});

export const addPromoCodeSchema = object().shape({
  code: string().required(),
  plan: optionalStringSchema,
  value: number().required(),
  validTo: string().required(),
});
