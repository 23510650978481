import styled from 'styled-components';
import { Form } from '../Form';
import { TextInput } from '../TextInput';

export const StyledForm = styled(Form)(
  () => `
  display: flex;
  flex-direction: column;
  gap: 20px;
`
);

export const StyledTextWrapper = styled('div')(
  () => `
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px
`
);

export const StyledInput = styled(TextInput)(
  () => `
  background-color: transparent;
`
);
