import { gtMedia } from 'config';
import styled from 'styled-components';

import { SpaceType } from 'types';

interface Props {
  size: number[];
  verticalMargin?: string;
  gap?: SpaceType;
}

export const GridComponent = styled.div<Props>`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: ${({ size }) => `repeat(auto ${size[1]}, 1fr)`};
  gap: ${({ theme: { spaces }, gap }) => (gap ? spaces[gap] : '0px')};

  ${gtMedia('sm')} {
    grid-template-columns: ${({ size }) => `repeat(${size[0]}, 1fr)`};
  }
`;
