import styled from 'styled-components';

import { gtMedia } from 'config';
import { ColorType } from 'types';

interface ContainerProps {
  color: ColorType;
  fullWidth: boolean;
}

export const Container = styled('div')<ContainerProps>(
  ({ theme: { colors, spaces }, color, fullWidth }) => `
  display: flex;
  gap: ${spaces.xs};
  align-items: center;
  background-color: ${colors[color]}1A;
  padding: 8px 14px;
  border-radius: 11px;
  color: ${colors[color]};
  width: ${fullWidth ? '100%' : 'auto'};

    ${gtMedia('lg')} {
    padding: 12px 16px;
  }
`
);
