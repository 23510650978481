import { colors } from 'config/theme/basicStyleValues/colors';
import { FC, useState } from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Customized,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartViewMode } from 'cogamika-back/types';
import { LevelLine } from '../LevelLine';
import { RenderCustomizedLabel } from '../Label';

export interface ChartData {
  name: string;
  progress: number;
  level: number;
}

interface ProgressChartProps {
  height?: number;
  data: ChartData[];
  viewMode: ChartViewMode;
}

export const Chart: FC<ProgressChartProps> = ({ data, height, viewMode }) => {
  const [barHoverIndex, setBarHoverIndex] = useState<number>();

  return (
    <ResponsiveContainer width="100%" height={height} minHeight={200}>
      <BarChart
        data={data}
        margin={{ top: 32, right: 30, left: -25, bottom: 0 }}
        maxBarSize={60}
        barCategoryGap={10}
      >
        <CartesianGrid vertical={false} />
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis
          axisLine={true}
          tickLine={false}
          tickCount={5}
          domain={[0, 100]}
        />
        <Bar
          dataKey="progress"
          fill={`${colors.info}50`}
          label={<RenderCustomizedLabel barHoveredIndex={barHoverIndex} />}
          onMouseOver={(__: any, index: number) => setBarHoverIndex(index)}
          onMouseLeave={() => setBarHoverIndex(undefined)}
        />
        <Customized component={<LevelLine viewMode={viewMode} />} />
      </BarChart>
    </ResponsiveContainer>
  );
};
