import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { __ } from 'utils/i18n';
import { FieldErrorMessage } from 'components/forms';
import { Flex, Icon, TranslateText } from 'components/common';

import {
  StyledInput,
  StyledInputContainer,
  Wrapper,
  StyledIconButton,
  StyledLabel,
} from './styles';

interface Props {
  name: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  autoComplete?: 'off' | 'new-password';
}

export const PasswordInput: React.FC<Props> = ({
  name,
  placeholder,
  required,
  disabled,
  label,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  const togglePassword = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <StyledInputContainer>
      <Flex columnDirection gap="xs" fullWidth>
        {label && (
          <StyledLabel htmlFor={name}>
            <TranslateText text={label} />
          </StyledLabel>
        )}
        <Wrapper>
          <StyledInput
            {...(register && register(name))}
            placeholder={
              required
                ? __(`placeholders.${placeholder}`) + '*'
                : __(`placeholders.${placeholder}`)
            }
            disabled={disabled}
            type={isVisible ? 'text' : 'password'}
            aria-invalid={!!error}
          />
          <StyledIconButton type="button" name="eye" onClick={togglePassword}>
            <Icon name="eye" />
          </StyledIconButton>
        </Wrapper>
      </Flex>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </StyledInputContainer>
  );
};
