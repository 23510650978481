import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from 'components/common';
import { routes } from 'config';
import { getSelectOptionsFromEnum } from 'utils';
import { Language } from 'cogamika-back/types';
import { ChangeLangSelect } from 'components/common/ChangeLangSelect';
import { IconButton } from 'components/common/Button/IconButton';

import {
  StyledLinksContainer,
  NavigationSection,
  MobileNavigationSection,
  MenuBtnWrapper,
  CloseMenuWrapper,
} from '../styles';

export const BaseLinks: React.FC = () => {
  const navigate = useNavigate();
  const [showMobileNav, setShowMobileNav] = useState(false);

  const redirectToRegister = () => {
    navigate(routes.register);
  };

  const redirectToLogin = () => {
    navigate(routes.login);
  };

  const handleOnSubscriptionClick = () => {
    navigate(routes.subscriptions);
  };

  const toggleNavigation = (show: boolean) => {
    setShowMobileNav(show);
  };

  const NAV_LINKS = (
    <>
      <LinkButton
        size="sm"
        text="button.subscription"
        uppercase
        onClick={handleOnSubscriptionClick}
      />
      <LinkButton
        size="sm"
        text="button.register"
        uppercase
        onClick={redirectToRegister}
        dataCy="open-register-modal"
      />
      <LinkButton
        size="sm"
        text="button.login"
        uppercase
        onClick={redirectToLogin}
        dataCy="open-login-modal"
      />
    </>
  );

  return (
    <StyledLinksContainer>
      <NavigationSection>{NAV_LINKS}</NavigationSection>
      <ChangeLangSelect
        options={getSelectOptionsFromEnum(Language, true, 'Shortcut')}
      />
      <MenuBtnWrapper>
        <IconButton
          name="menu"
          onClick={() => toggleNavigation(true)}
          type="button"
          size="sm"
        />
      </MenuBtnWrapper>

      <MobileNavigationSection isMobileNavOpen={showMobileNav}>
        <CloseMenuWrapper>
          <IconButton
            name="cross"
            onClick={() => toggleNavigation(false)}
            type="button"
            size="sm"
          />
        </CloseMenuWrapper>
        {NAV_LINKS}
      </MobileNavigationSection>
    </StyledLinksContainer>
  );
};
