import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MoviePressedResult } from 'types';
import { Movie } from 'games';

import { ButtonContainer, Container, ReactButton, StyledVideo } from './styles';
import { apiUrl } from 'config';

interface Props {
  movie: Movie;
  endGame: () => void;
}

export const Game: React.FC<Props> = ({ movie, endGame }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlay, setIsVideoPlay] = useState(false);
  const [buttonStatus, setButtonStatus] = useState<MoviePressedResult>(
    MoviePressedResult.InProgress
  );

  const handleClick = () => {
    if (!videoRef.current) return;

    const currentTime = videoRef.current.currentTime;
    const { result, duration } = movie.pressButton(currentTime);
    setButtonStatus(result);

    if (result !== MoviePressedResult.InProgress)
      setTimeout(
        () => {
          setButtonStatus(MoviePressedResult.InProgress);
        },
        duration ? duration * 1000 : 500
      );
  };

  const keyboardClick = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (
        (e.code === 'Space' || e.code === 'Enter') &&
        buttonStatus === MoviePressedResult.InProgress
      ) {
        handleClick();
      }
    },
    [buttonStatus]
  );

  useEffect(() => {
    window.addEventListener('keypress', keyboardClick);

    return () => {
      window.removeEventListener('keypress', keyboardClick);
    };
  }, [keyboardClick]);

  const isButtonDisabled =
    !isVideoPlay || buttonStatus !== MoviePressedResult.InProgress;

  return (
    <Container>
      <StyledVideo
        onPlay={() => setIsVideoPlay(true)}
        onPause={() => setIsVideoPlay(false)}
        onEnded={() => endGame()}
        ref={videoRef}
        autoPlay
        src={`${apiUrl}file/${movie.movieId}`}
        disablePictureInPicture
        playsInline
      />
      <ButtonContainer>
        <ReactButton
          result={buttonStatus}
          text="button.react"
          variant="primary"
          uppercase
          onClick={handleClick}
          disabled={isButtonDisabled}
        />
      </ButtonContainer>
    </Container>
  );
};
