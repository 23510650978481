import styled from 'styled-components';

import { gtMedia } from 'config';
import { Text } from 'components/common';

interface NavItemContainerProps {
  active: boolean;
}

interface NavItemTextProps {
  active: boolean;
}

export const NavItemText = styled(Text)<NavItemTextProps>(
  ({ active, theme: { fontSizes } }) => `
  font-weight: ${active ? '700' : '500'};
  font-size: ${fontSizes.xs};

  ${gtMedia('lg')} {
    font-size: ${fontSizes.base};
  }
`
);

export const MobileNavWrapper = styled('div')(
  ({ theme: { layoutHorizontalSpaces } }) => `
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  & > :first-child {
    padding-left: ${layoutHorizontalSpaces.xs};
    margin-left: -${layoutHorizontalSpaces.xs};
  }

  & > :last-child {
    margin-right: -${layoutHorizontalSpaces.xs};
    width: 100%;
  }

  ${gtMedia('sm')} {
    & > :first-child {
      padding-left: ${layoutHorizontalSpaces.sm};
      margin-left: -${layoutHorizontalSpaces.sm};
    }

    & > :last-child {
      margin-right: -${layoutHorizontalSpaces.sm};
      width: 100%;
    }
  }
`
);

export const MobileNavItemContainer = styled('button')<NavItemContainerProps>(
  ({ theme, active }) => `
  display: flex;
  align-items: center;
  border: none;
  background: none;
  box-shadow: ${theme.boxShadows.basic};
  border-bottom: 2px solid ${theme.colors.primaryBorder};
  border-color: ${active ? theme.colors.secondary : theme.colors.primaryBorder};
  padding: ${theme.spaces.base};
  cursor: pointer;

  &:hover {
    ${NavItemText} {
      font-weight: ${theme.fontWeight.bold};
    }
  }
`
);

export const MobileNavItemText = styled(Text)<NavItemTextProps>(
  ({ active }) => `
  font-weight: ${active ? '700' : '500'};
  white-space: nowrap;
`
);

export const FakeDiv = styled('div')(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  border-bottom: 2px solid ${theme.colors.primaryBorder};
  display: none;

  ${gtMedia('sm')} {
    display: block;
  }

  ${gtMedia('md')} {
    display: none;
  }
  `
);
