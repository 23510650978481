import { FontSizes } from 'types';

export const fontSizes: FontSizes = {
  '2xs': '0.875rem',
  xs: '1rem',
  sm: '1.125rem',
  base: '1.25rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '2.25rem',
  '2xl': '3rem',
};

export const lineHeights: FontSizes = {
  '2xs': '1.025rem',
  xs: '1.2rem',
  sm: '1.32rem',
  base: '1.47rem',
  md: '1.76rem',
  lg: '2.25rem',
  xl: '2.64rem',
  '2xl': '3.5rem',
};
