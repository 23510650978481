import React from 'react';

import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
  Icon,
} from 'components/common';
import { MainContainer, Wrapper } from './styles';
import { useScreenOrientationType } from 'hooks';

interface Props {
  onLeave: () => void;
}

export const RotateScreen: React.FC<Props> = ({ onLeave }) => {
  const { screenOrientationType } = useScreenOrientationType();

  const diabledBtn =
    screenOrientationType === 'portrait-primary' ||
    screenOrientationType === 'portrait-secondary';

  return (
    <Wrapper>
      <MainContainer>
        <Flex columnDirection gap="base" alignItems="center">
          <Icon name="rotate" />
          <Flex columnDirection alignItems="center" gap="xs">
            <Heading
              text="modals.rotateScreen.header"
              size="base"
              weight="semibold"
            />
            <Text
              text="modals.rotateScreen.description"
              size="xs"
              textAlign="center"
            />
          </Flex>
          <FlexAlignCenter gap="md" fullWidth>
            <Button
              type="button"
              variant="secondary"
              onClick={onLeave}
              text={'button.ok'}
              uppercase
              fullWidth
              disabled={diabledBtn}
            />
          </FlexAlignCenter>
        </Flex>
      </MainContainer>
    </Wrapper>
  );
};
