import { ltMedia, gtMedia } from 'config';
import styled from 'styled-components';

interface BarProps {
  progress: number;
}

export const ProgressContainer = styled('div')(
  () => `
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  min-width: 300px;
  gap: 6px;

  ${gtMedia('sm')} {
    flex-direction: row;
  }
`
);

export const BarContainer = styled('div')(
  () => `
  display: flex;
  align-items: center;
  gap: 15px;
  width: 40%;
  min-width: 150px;

  ${ltMedia('sm')} {
    align-items: start;
    min-width: unset;
    width: 100%;
  }
`
);

export const Bar = styled('div')<BarProps>(
  ({ theme: { colors }, progress }) => `
  background-color: ${colors.primaryBackground};
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 23px;
  border-radius: 26px;

  &::after {
    display: block;
    content: "";
    height: 100%;
    width: ${progress}%;
    border-radius: 26px;
    background-color: ${colors.green};
  }
`
);

export const BarNumberContainer = styled('div')`
  width: 34px;
`;
