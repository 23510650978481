import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { __ } from 'utils/i18n';
import { TranslateText } from 'components/common';
import { FieldErrorMessage } from 'components/forms';

import {
  StyledInput,
  StyledInputContainer,
  StyledLabel,
  Wrapper,
} from './style';

interface Props {
  name: string;
  placeholder: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: 'off' | 'new-password';
  password?: boolean;
  fullWidth?: boolean;
  onBlurAction?: (value: string) => unknown;
  onClick?: () => void;
  label?: string;
  className?: string;
}

export const TextInput: React.FC<Props> = ({
  name,
  placeholder,
  required,
  disabled,
  password,
  fullWidth,
  onBlurAction,
  onClick,
  label,
  className,
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <StyledInputContainer fullWidth={fullWidth}>
      <Wrapper>
        {label && (
          <StyledLabel htmlFor={name}>
            <TranslateText text={label} />
          </StyledLabel>
        )}
        <StyledInput
          {...(register &&
            register(name, {
              onBlur: (event) =>
                onBlurAction && onBlurAction(event.target.value),
            }))}
          placeholder={
            required
              ? __(`placeholders.${placeholder}`) + '*'
              : __(`placeholders.${placeholder}`)
          }
          disabled={disabled}
          type={password ? 'password' : ''}
          aria-invalid={!!error}
          onClick={onClick}
          className={className}
        />
      </Wrapper>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </StyledInputContainer>
  );
};
