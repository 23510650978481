import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColorType } from 'types';
import { GameNavigation } from 'components/common';
import { clearSessionGames } from 'slices';
import { routes } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useMediaQuery,
  useScreenOrientationType,
} from 'hooks';
import { CloseGame, InstructionModal, RotateScreen } from 'components/games';

import { StyledGameLayout, StyledMainContainer } from './styles';

interface Props {
  children: React.ReactNode;
  bgColor?: ColorType;
}

export const GameLayout: React.FC<Props> = ({
  children,
  bgColor = 'primaryBackground',
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { screenOrientationType } = useScreenOrientationType();
  const { instruction } = useAppSelector((state) => state.game);
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);
  const [isInstructionOpen, setIsInstructionOpen] = useState<boolean>(false);
  const [isRotatingScreenOpen, setIsRotatingScreenOpen] =
    useState<boolean>(false);
  const { isSmallScreen, isMediumScreen } = useMediaQuery();

  const onShowPopUp = () => setIsLeavingGame(true);
  const onClosePopUp = () => setIsLeavingGame(false);

  const onOpenInstruction = () => setIsInstructionOpen(true);
  const onCloseInstruction = () => setIsInstructionOpen(false);

  const onCloseRotatingScreen = () => setIsRotatingScreenOpen(false);

  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  useEffect(() => {
    if (
      (isSmallScreen || isMediumScreen) &&
      (screenOrientationType === 'portrait-primary' ||
        screenOrientationType === 'portrait-secondary')
    ) {
      setIsRotatingScreenOpen(true);
    }
  }, [isSmallScreen, isMediumScreen, screenOrientationType]);

  return (
    <StyledGameLayout bgColor={bgColor}>
      <GameNavigation
        onClose={onShowPopUp}
        onOpenInstruction={onOpenInstruction}
      />
      <StyledMainContainer>{children}</StyledMainContainer>
      {isLeavingGame && (
        <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
      )}
      {isInstructionOpen && instruction && (
        <InstructionModal
          fullInstruction={instruction}
          onClose={onCloseInstruction}
        />
      )}
      {isRotatingScreenOpen && <RotateScreen onLeave={onCloseRotatingScreen} />}
    </StyledGameLayout>
  );
};
