import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface Props {
  disablebtn: boolean;
}

export const StyledNavLink = styled(Link)<Props>(
  ({ theme: { colors, spaces, fontSizes, lineHeights }, disablebtn }) => `
    display: flex;
    align-items: center;
    gap: ${spaces.sm};
    justify-context: space-between;
    color: ${disablebtn ? colors.gray : colors.primary};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: ${fontSizes.sm};
    line-height: ${lineHeights.base};
    &:hover {
      color: ${disablebtn ? colors.gray : colors.secondary};
      cursor: ${disablebtn ? 'not-allowed' : 'pointer'};
    }
`
);
