import styled from 'styled-components';

import { gtMedia } from 'config';
import { getMargin, getPadding } from 'utils';
import {
  Theme,
  ButtonType,
  MarginType,
  PaddingType,
  ButtonSizeType,
  JustifyContentType,
} from 'types';

interface Props {
  variant: ButtonType;
  margin?: MarginType;
  fullWidth?: boolean;
  uppercase?: boolean;
  justifyContent?: JustifyContentType;
  noWrap?: boolean;
  padding?: PaddingType;
  size?: ButtonSizeType;
}

export const getSize = (size: ButtonSizeType) => {
  switch (size) {
    case 'xs':
      return `
        width: 100px;
    `;
    case 'sm':
      return `
        width: 150px;
    `;
    case 'base':
      return `
        width: 264px;
    `;
    case 'lg':
      return `
          width: 336px
        `;
  }
};

const getVariant = (variant: ButtonType, theme: Theme) => {
  switch (variant) {
    case 'primary':
      return `
        background-color: ${theme.colors.primary};
        color: ${theme.colors.light};
        &:hover {
          background-color: ${theme.colors.primaryHover};
        }
        &: disabled {
          background-color: ${theme.colors.disable};
        }
      `;
    case 'secondary':
      return `
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.light};
        &:hover {
          background-color: ${theme.colors.secondaryHover};
        }
        &: disabled {
          background-color: ${theme.colors.disable};
        }
      `;
    case 'tertiary':
      return `
        background: none;
        border: none
        color: ${theme.colors.primary};
        font-size: ${theme.fontSizes.xs};
        height: 24px;

        &:hover {
          color: ${theme.colors.secondary};
        }
        &: disabled {
          color: ${theme.colors.disable};
        }
      `;
    case 'outlined':
      return `
        color: ${theme.colors.primary};
        border: 2px solid ${theme.colors.primary};
        background-color: transparent;
        &:hover {
          border-color: ${theme.colors.secondary};
          color: ${theme.colors.secondary};
        }
      `;
    case 'outlined-small':
      return `
        font-size: ${theme.fontSizes['2xs']};
        padding: 10px 16px;
        height: 36px;
        color: ${theme.colors.primary};
        border: 2px solid ${theme.colors.primary};
        background-color: transparent;

        &:hover {
          border-color: ${theme.colors.secondary};
          color: ${theme.colors.secondary};
        }
      `;
    case 'gray':
      return `
        background-color: ${theme.colors.gray};
        color: ${theme.colors.primary};
        &:hover {
          background-color: ${theme.colors.lightGray};
        }
      `;
    case 'outlined-success':
      return `
        background-color: ${theme.colors.secondarySuccess};
        color: ${theme.colors.darkGreen};
        border: 2px solid ${theme.colors.darkGreen};

        &:hover {
          background-color: ${theme.colors.darkGreen};
          color: ${theme.colors.light};
        }
      `;
    case 'outlined-error':
      return `
          background-color: ${theme.colors.warningBackground};
          color: ${theme.colors.error};
          border: 2px solid ${theme.colors.error};

          &:hover {
            background-color: ${theme.colors.error};
            color: ${theme.colors.light};
          }
        `;
    case 'domains-filter':
      return `
        padding: ${theme.spaces.xs} ${theme.spaces.base};
        background-color: ${theme.colors.gray};
        border: 2px solid ${theme.colors.secondaryBorder};
        border-radius: ${theme.radius['4xl']};
        height: 37px;
        font-size: ${theme.fontSizes.sm};
        font-weight: ${theme.fontWeight.medium};
        &:hover {
          background-color: ${theme.colors.lightGray};
        }
      `;
    case 'domains-filter-selected':
      return `
        color: ${theme.colors.light};
        padding: ${theme.spaces.xs} ${theme.spaces.base};
        background-color: ${theme.colors.secondary};
        border: 2px solid ${theme.colors.secondary};
        border-radius: ${theme.radius['4xl']};
        height: 37px;
        font-size: ${theme.fontSizes.sm};
        font-weight: ${theme.fontWeight.medium};
        &:hover {
          background-color: ${theme.colors.violet};
          border-color: ${theme.colors.violet};
        }
      `;
    case 'cookie-cancel':
      return `
        color: ${theme.colors.secondaryBackground};
        background-color: transparent;
        border: 2px solid ${theme.colors.secondaryBackground};
        &:hover {
          background-color: ${theme.colors.violet};
          border-color: ${theme.colors.violet};
        }
      `;
    case 'cookie-accept':
      return `
        color: ${theme.colors.primary};
        background-color: ${theme.colors.secondaryBackground};
        &:hover {
          background-color: ${theme.colors.violet};
          color: ${theme.colors.secondaryBackground};
        }
      `;
    case 'edit-profile':
      return `
        background-color: ${theme.colors.primary};
        color: ${theme.colors.light};
        font-size: 14px;
        padding: 10px 12px;
        height: 36px;
        white-space: nowrap;
        font-weight: ${theme.fontWeight.medium};
        border-radius: ${theme.radius.md};

        &:hover {
          background-color: ${theme.colors.primaryHover};
        }
        &: disabled {
          background-color: ${theme.colors.disable};
        }
      `;
    case 'renew-subscription':
      return `
        height: 36px;
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.light};
        font-size: 14px;
        width: 100%;
        padding: 10px 16px;

        &:hover {
          background-color: ${theme.colors.secondaryHover};
        }
        &: disabled {
          background-color: ${theme.colors.disable};
        }

        ${gtMedia('sm')} {
          width: auto;
        }

        ${gtMedia('lg')} {
          height: 48px;
          font-size: ${theme.fontSizes.sm};
          padding: 18px 32px;
        }
      `;
    case 'show-video':
      return `
        color: ${theme.colors.secondary};
        padding: 10px 16px;
        font-size: ${theme.fontSizes['2xs']};
        height: 36px;
        background-color: ${theme.colors.teritaryBackground};
      `;
  }
};

export const StyledButton = styled.button<Props>(
  ({
    theme: { fontSizes, lineHeights, spaces, fontWeight },
    theme,
    margin,
    fullWidth,
    variant,
    uppercase,
    justifyContent,
    noWrap,
    padding,
    size,
  }) => `
  .left-padding: {
    padding: 0 !important;
  }
  display: flex;
  justify-content: ${justifyContent};
  gap: 16px;
  align-items: center;
  cursor: pointer;
  font-weight: ${fontWeight.semibold};
  border-style: none;
  font-size: ${fontSizes.sm};
  line-height: ${lineHeights.sm};
  border-radius: ${theme.radius.md};
  box-sizing: border-box;
  height: 48px;
  padding: 16px 32px 16px 32px;
  text-transform: ${uppercase ? 'uppercase' : ''};
  width: ${fullWidth ? '100%' : 'auto'};

  &:focus-visible {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }

  ${padding && getPadding(padding, spaces)}
  ${margin && getMargin(margin, spaces)};
  ${getVariant(variant, theme)};
  ${noWrap && 'white-space: nowrap'};
  ${size && getSize(size)};
`
);
