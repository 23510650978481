import React, { useEffect } from 'react';

import { MemoryCardType } from 'types';
import { StyledImage } from './styles';
import { apiUrl } from 'config';

interface Props {
  card: MemoryCardType;
  isAllImagesLoaded: boolean;
  handleClick: () => void;
  handleImageLoad: () => void;
}

const MemoryCard: React.FC<Props> = ({
  card,
  isAllImagesLoaded,
  handleClick,
  handleImageLoad,
}) => {
  const { isVisible, imageId, isFound } = card;

  useEffect(() => {
    const image = new Image();
    image.src = `${apiUrl}file/${imageId}`;
    image.onload = () => {
      handleImageLoad();
    };

    // eslint-disable-next-line
  }, [imageId]);

  return (
    <>
      <StyledImage
        src="/assets/images/card_rectangle.png"
        onClick={handleClick}
        data-cy="card"
        isVisible={!isVisible}
      />
      {isAllImagesLoaded && (
        <StyledImage
          src={`${apiUrl}file/${imageId}`}
          isVisible={isVisible}
          isFound={isFound}
          isBoxShadow={isVisible}
        />
      )}
    </>
  );
};

export default MemoryCard;
