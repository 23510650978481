import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ColorType } from 'types';
import { Button, Flex, GameNavigation } from 'components/common';
import { clearInstruction, clearSessionGames } from 'slices';
import { AppConfig, adminRoutes, routes, superAdminRoutes } from 'config';
import {
  useAppDispatch,
  useAppSelector,
  useMediaQuery,
  useScreenOrientationType,
} from 'hooks';
import { CloseGame, InstructionModal, RotateScreen } from 'components/games';
import { UserRole } from 'cogamika-back/types';
import { replaceParams } from 'utils';
import { __ } from 'utils/i18n';

import {
  StyledGameLayout,
  StyledLevelContainer,
  StyledMainContainer,
} from './styles';

interface Props {
  children: React.ReactNode;
  bgColor?: ColorType;
}

export const GameTestLayout: React.FC<Props> = ({
  children,
  bgColor = 'primaryBackground',
}) => {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { screenOrientationType } = useScreenOrientationType();
  const { user } = useAppSelector((state) => state.user);
  const { instruction } = useAppSelector((state) => state.game);
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);
  const [isInstructionOpen, setIsInstructionOpen] = useState<boolean>(false);
  const [isRotatingScreenOpen, setIsRotatingScreenOpen] =
    useState<boolean>(false);
  const { isSmallScreen, isMediumScreen } = useMediaQuery();

  const onShowPopUp = () => setIsLeavingGame(true);
  const onClosePopUp = () => setIsLeavingGame(false);

  const onOpenInstruction = () => setIsInstructionOpen(true);
  const onCloseInstruction = () => setIsInstructionOpen(false);

  const onCloseRotatingScreen = () => setIsRotatingScreenOpen(false);

  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  const changeLevel = (level: number) => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.gameTest
        : adminRoutes.gameTest;
    dispatch(clearInstruction());
    dispatch(clearSessionGames());
    navigate(replaceParams(url, { gameId, level }));
  };

  const editGame = () => {
    const url =
      user?.role === UserRole.SuperAdmin
        ? superAdminRoutes.editGame
        : adminRoutes.editGame;
    dispatch(clearInstruction());
    dispatch(clearSessionGames());
    navigate(replaceParams(url, { id: gameId }));
  };

  const renderLevelButtons = () =>
    AppConfig.GAME_LEVELS.map((item) => (
      <Button
        fullWidth
        key={item}
        text={__('views.gameTest.level', { level: item })}
        noTranslate
        variant="outlined"
        onClick={() => changeLevel(item)}
      />
    ));

  useEffect(() => {
    if (
      (isSmallScreen || isMediumScreen) &&
      (screenOrientationType === 'portrait-primary' ||
        screenOrientationType === 'portrait-secondary')
    ) {
      setIsRotatingScreenOpen(true);
    }
  }, [isSmallScreen, isMediumScreen, screenOrientationType]);

  return (
    <StyledGameLayout bgColor={bgColor}>
      <GameNavigation
        onClose={onShowPopUp}
        onOpenInstruction={onOpenInstruction}
      />
      <Flex alignItems="center">
        <StyledMainContainer>{children}</StyledMainContainer>
        <StyledLevelContainer>
          <Button
            text="button.editGame"
            variant="secondary"
            onClick={editGame}
          />
          {renderLevelButtons()}
        </StyledLevelContainer>
      </Flex>
      {isLeavingGame && (
        <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
      )}
      {isInstructionOpen && instruction && (
        <InstructionModal
          fullInstruction={instruction}
          onClose={onCloseInstruction}
        />
      )}
      {isRotatingScreenOpen && <RotateScreen onLeave={onCloseRotatingScreen} />}
    </StyledGameLayout>
  );
};
