import React from 'react';

import { useMediaQuery } from 'hooks';
import {
  Text,
  Heading,
  Flex,
  FlexAlignCenter,
  Button,
} from 'components/common';

import { MainContainer, Wrapper } from './styles';

interface Props {
  onLeave: () => void;
  onClose: () => void;
}

export const CloseGame: React.FC<Props> = ({ onClose, onLeave }) => {
  const { isGtLarge } = useMediaQuery();

  return (
    <Wrapper>
      <MainContainer>
        <Flex columnDirection gap={isGtLarge ? '2xl' : 'base'}>
          <Flex columnDirection gap={isGtLarge ? 'sm' : 'xs'}>
            <Heading
              text="modals.closeGame.header"
              size={isGtLarge ? 'md' : 'base'}
              weight="semibold"
            />
            <Text
              text="modals.closeGame.description"
              size={isGtLarge ? 'base' : 'xs'}
            />
          </Flex>
          <FlexAlignCenter columnDirection={!isGtLarge} gap="sm" fullWidth>
            <Button
              type="button"
              variant="outlined"
              onClick={onClose}
              text="button.backToGame"
              uppercase
              fullWidth={!isGtLarge}
            />
            <Button
              type="button"
              variant="secondary"
              onClick={onLeave}
              text={'button.leaveGame'}
              uppercase
              fullWidth={!isGtLarge}
            />
          </FlexAlignCenter>
        </Flex>
      </MainContainer>
    </Wrapper>
  );
};
