import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { apiUrl, routes } from 'config';
import { clearSessionGames } from 'slices';
import { useAppDispatch, useMediaQuery } from 'hooks';
import { Flex, Text, Button, Heading, GridItem } from 'components/common';
import { CloseGame, InstructionVideo } from 'components/games';

import { DomainsInfo } from './DomainsInfo';
import {
  DescriptionContainer,
  GridItemVideo,
  InstructionContainer,
  InstructionGridContainer,
  StyledVideo,
} from './style';

interface Props {
  instruction: string;
  instructionFileId: string | null;
  startGame: () => void;
}

export const Instruction: React.FC<Props> = ({
  instruction,
  instructionFileId,
  startGame,
}) => {
  const [isLeavingGame, setIsLeavingGame] = useState<boolean>(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isSmallScreen, isMediumScreen, isGtMedium } = useMediaQuery();

  const startGameHandler = () => {
    startGame();
  };

  const onLeaveGame = () => {
    dispatch(clearSessionGames());
    navigate(routes.root);
  };

  const onShowPopUp = () => setIsLeavingGame(true);
  const onClosePopUp = () => setIsLeavingGame(false);

  const onShowVideoModal = () => setIsVideoModalOpen(true);
  const onCloseVideoModal = () => setIsVideoModalOpen(false);

  return (
    <InstructionContainer>
      <DomainsInfo />
      <InstructionGridContainer
        size={isSmallScreen || isMediumScreen ? [1] : [2]}
        gap={isSmallScreen || isMediumScreen ? undefined : '7xl'}
      >
        {isGtMedium && (
          <GridItemVideo>
            {instructionFileId && (
              <StyledVideo
                controls
                src={`${apiUrl}file/${instructionFileId}`}
                disablePictureInPicture
                autoPlay
              ></StyledVideo>
            )}
          </GridItemVideo>
        )}
        <GridItem>
          <Flex columnDirection justifyContent="space-between" fullWidth>
            <Flex columnDirection gap="xs" fullWidth>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                fullWidth
              >
                <Heading
                  text="views.instruction.heading"
                  size="base"
                  color="primary"
                  weight="semibold"
                />
                {(isSmallScreen || isMediumScreen) && (
                  <Button
                    variant="show-video"
                    text="button.showVideo"
                    uppercase
                    onClick={onShowVideoModal}
                  />
                )}
              </Flex>
              <DescriptionContainer>
                <Text
                  whiteSpace={isGtMedium ? 'pre-wrap' : 'none'}
                  size="xs"
                  margin={['', '', 'lg', '']}
                  text={instruction}
                  noTranslate
                />
              </DescriptionContainer>
            </Flex>
            <Flex gap="lg" justifyContent="flex-end" fullWidth>
              <Button
                variant="outlined"
                text="button.cancel"
                onClick={onShowPopUp}
                dataCy="cancel-session"
                uppercase
              />
              <Button
                variant="secondary"
                text="button.start"
                onClick={startGameHandler}
                dataCy="start-game"
                uppercase
              />
            </Flex>
          </Flex>
        </GridItem>
      </InstructionGridContainer>
      {isLeavingGame && (
        <CloseGame onClose={onClosePopUp} onLeave={onLeaveGame} />
      )}
      {isVideoModalOpen && instructionFileId && (
        <InstructionVideo
          onClose={onCloseVideoModal}
          instructionFileId={instructionFileId}
        />
      )}
    </InstructionContainer>
  );
};
