import styled from 'styled-components';
import { Button } from '../Button';
import { ColorType } from 'types';
import { gtMedia } from 'config';

interface ButtonProps {
  color: ColorType;
}

export const SubscriptionPackageWrapper = styled('div')(
  ({ theme: { boxShadows, colors } }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${boxShadows.subscriptionPackage};

  ${gtMedia('sm')} {
    flex-direction: row-reverse;
  }

  ${gtMedia('md')} {
    flex-direction: column;
  }
`
);

export const SubscriptionPackageImage = styled('div')(
  ({ theme: { colors } }) => `
  min-height: 225px;
  background-color: ${colors.gray};
  flex-basis: 50%;
`
);

export const SubscriptionPackageContent = styled('div')(
  () => `
  display: flex;
  gap: 18px;
  padding: 30px 24px 24px 24px;
  flex-basis: 50%;
`
);

export const SubscriptionButton = styled(Button)<ButtonProps>(
  ({ color, theme }) => `
  background-color: ${theme.colors[color]};
`
);
