import { useEffect, useState } from 'react';

export const useScreenOrientationType = () => {
  const [screenOrientationType, setScreenOrientationType] =
    useState<OrientationType>(window.screen.orientation.type);

  const handleOrientationChange = () => {
    setScreenOrientationType(window.screen.orientation.type);
  };

  useEffect(() => {
    window.screen.orientation.addEventListener(
      'change',
      handleOrientationChange
    );

    return () => {
      window.screen.orientation.removeEventListener(
        'change',
        handleOrientationChange
      );
    };
  }, []);

  return {
    screenOrientationType,
  };
};
