import React from 'react';

import { GameResult } from 'types';
import { Survey } from 'games';
import { useMediaQuery, useSurveyGame } from 'hooks';
import { Text } from 'components/common';

import { Answer } from './Answer';
import { Question } from './Question';
import { Result } from './Result';
import { Container, AnswersContainer, AnswersGrid } from './styles';

interface Props {
  survey: Survey;
  endGame: (result?: GameResult) => void;
}

export const Game: React.FC<Props> = ({ survey, endGame }) => {
  const { isGtLarge } = useMediaQuery();
  const {
    currentQuestionIndex,
    questionWithAnswers,
    selectedAnswer,
    result,
    correctAnswerIndex,
    answerType,
    selectAnswer,
    nextQuestion,
  } = useSurveyGame({ survey, endGame });

  return (
    <Container>
      <Question question={questionWithAnswers.question} />
      <AnswersContainer>
        <Text
          text="views.survey.answersHeader"
          size={!isGtLarge ? 'base' : 'md'}
          color="teritaryGray"
          weight="semibold"
        />
        <AnswersGrid variant={answerType}>
          {questionWithAnswers.answers.map((answer, index) => (
            <Answer
              key={index}
              answer={answer}
              selectAnswer={selectAnswer}
              index={index}
              selectedAnswerIndex={selectedAnswer}
              survey={survey}
            />
          ))}
        </AnswersGrid>
      </AnswersContainer>
      <Result
        result={result}
        correctAnswerIndex={correctAnswerIndex}
        handleNext={() => nextQuestion(currentQuestionIndex + 1)}
      />
    </Container>
  );
};
