import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { TranslateText } from 'components/common';
import { FieldErrorMessage } from 'components/forms';

import {
  StyledRadio,
  StyledRadioContainer,
  StyledLabel,
  Wrapper,
} from './style';

interface Props {
  name: string;
  value: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
}

export const RadioButton: React.FC<Props> = ({
  name,
  label,
  value,
  required,
  disabled,
  onClick,
  children,
  className,
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);

  return (
    <StyledRadioContainer>
      <Wrapper>
        <StyledRadio
          {...(register &&
            register(name, {
              required,
            }))}
          type="radio"
          value={value}
          disabled={disabled}
          aria-invalid={!!error}
          onClick={onClick}
          className={className}
          id={`${name}-${value}`}
        />
        <StyledLabel htmlFor={`${name}-${value}`}>
          {children || (label && <TranslateText text={label} />)}
        </StyledLabel>
      </Wrapper>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage text={message} />}
        />
      )}
    </StyledRadioContainer>
  );
};
