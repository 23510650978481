import styled from 'styled-components';

import { MoviePressedResult, Theme } from 'types';
import { Button } from 'components/common';
import { gtMedia } from 'config';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
    padding: 0 ${spaces['6xl']};
    height: 100%;
    position: relative;

    ${gtMedia('md')} {
      padding-top: 70px;
      padding-bottom: 70px;
    }
`
);

export const StyledVideo = styled.video`
  height: 321px;
  width: 570px;

  ${gtMedia('md')} {
    height: 468px;
    width: 832px;
  }

  ${gtMedia('lg')} {
    height: 648px;
    width: 1152px;
  }

  ${gtMedia('xl')} {
    height: 756px;
    width: 1344px;
  }
`;

interface ReactButtonProps {
  result: MoviePressedResult;
}

const getVariant = (result: MoviePressedResult, theme: Theme) => {
  switch (result) {
    case MoviePressedResult.Sucess:
      return `
        color: ${theme.colors.light};
        background-color: ${theme.colors.success};
        border-color: ${theme.colors.success};
        &:disabled {
          color: ${theme.colors.light};
          background-color: ${theme.colors.success};
        }
        &:hover {
          color: ${theme.colors.light};
          background-color: ${theme.colors.success};
        }
      `;
    case MoviePressedResult.Failure:
      return `
        color: ${theme.colors.light};
        background-color: ${theme.colors.error};
        border-color: ${theme.colors.error};
        &:disabled {
          color: ${theme.colors.light};
          background-color: ${theme.colors.error};
        }
        &:hover {
          color: ${theme.colors.light};
          background-color: ${theme.colors.error};
        }
      `;
    case MoviePressedResult.InProgress:
      return `
        &:hover:not([disabled]) {
          background-color: ${theme.colors.light};
          color: ${theme.colors.secondary};
          border-color: ${theme.colors.secondary};
        }
      `;
  }
};

export const ButtonContainer = styled.div(
  ({ theme: { spaces } }) => `
    position: absolute;
    bottom: 34px;
    left: 50%;
    transform: translateX(-50%);

    ${gtMedia('md')} {
      position: unset;
      transform: none;
      display: flex;
      justify-content: center;
      margin-top: ${spaces['2xl']};
    }

    ${gtMedia('lg')} {
      margin-top: ${spaces['6xl']};
    }
`
);

export const ReactButton = styled(Button)<ReactButtonProps>(
  ({ theme: { fontSizes, colors }, theme, result }) => `
    padding: 18px 32px;
    font-size: ${fontSizes.sm};
    background-color: ${colors.light};
    color: ${colors.primary};
    display: flex;
    height: auto;
    border: 1px solid ${colors.primary};
    ${getVariant(result, theme)};

    ${gtMedia('lg')} {
      font-size: ${fontSizes.base};
      padding: 18px 40px;
    }
`
);
