import {
  AddTrialPromoReq,
  BasePaginationRes,
  SuperAdminTrialPromoRes,
  TrialPromoRes,
} from 'cogamika-back/types';
import { BasePaginationContext } from 'context';
import { useContext, useState } from 'react';
import { successNotification } from 'slices';
import { prepareApiParamsObj } from 'utils';
import { useAppDispatch } from './redux';
import { useApi } from './useApi';

interface UseTrialPromoCodes {
  getTrialPromoCodes: () => Promise<void>;
  addTrialPromoCodes: (data: AddTrialPromoReq) => Promise<void>;
  removeTrialPromoCodes: (id: string) => Promise<void>;
  checkTrialPromoCode: (code: string) => Promise<void>;
  trialPromoCodesData: SuperAdminTrialPromoRes[];
  isValidCode: boolean;
  trialPromo?: TrialPromoRes;
}

export const useTrialPromoCodes = (): UseTrialPromoCodes => {
  const { setPagesPossibility, pagination } = useContext(BasePaginationContext);
  const [trialPromoCodesData, setTrialPromoCodesData] = useState<
    SuperAdminTrialPromoRes[]
  >([]);
  const [isValidCode, setIsValidCode] = useState<boolean>(true);
  const [trialPromo, setTrialPromo] = useState<TrialPromoRes>();
  const { get, post, remove } = useApi();
  const dispatch = useAppDispatch();

  const checkTrialPromoCode = async (code: string) => {
    setIsValidCode(true);
    const result = await get<TrialPromoRes>(`stripe/trial-promo/check/${code}`);

    if (!result) {
      setIsValidCode(false);
      setTrialPromo(undefined);
    } else {
      setIsValidCode(true);
      setTrialPromo(result);
    }
  };

  const getTrialPromoCodes = async () => {
    const result = await get<BasePaginationRes<SuperAdminTrialPromoRes>>(
      'stripe/trial-promo/list',
      prepareApiParamsObj({ ...pagination })
    );

    if (result) {
      const { items, hasNextPage, hasPreviousPage } = result;
      setTrialPromoCodesData(items);
      setPagesPossibility({
        hasNextPage,
        hasPreviousPage,
      });
    }
  };

  const addTrialPromoCodes = async (data: AddTrialPromoReq) => {
    const result = await post<SuperAdminTrialPromoRes, AddTrialPromoReq>(
      'stripe/trial-promo',
      data
    );

    if (result) {
      dispatch(successNotification('notifications.successfullyAddedCode'));
      getTrialPromoCodes();
    }
  };

  const removeTrialPromoCodes = async (id: string) => {
    const result = await remove<SuperAdminTrialPromoRes>(
      `stripe/trial-promo/${id}`
    );

    if (result) {
      dispatch(successNotification('notifications.successfullyDeletedCode'));
      getTrialPromoCodes();
    }
  };

  return {
    trialPromo,
    isValidCode,
    trialPromoCodesData,
    checkTrialPromoCode,
    getTrialPromoCodes,
    addTrialPromoCodes,
    removeTrialPromoCodes,
  };
};
