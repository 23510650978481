import { FC } from 'react';
import { Cross } from 'recharts';
import { colors } from 'config/theme/basicStyleValues/colors';
import { __ } from 'utils/i18n';
import { ChartViewMode } from 'cogamika-back/types';
import { ChartData } from '../Chart';

//@Cannot find correct type
interface DataType extends ChartData {
  x: number;
  y: number;
  width: number;
  //...and more
}

interface Props {
  viewMode: ChartViewMode;
  height?: number;
  formattedGraphicalItems?: {
    props: {
      data: DataType[];
      //...and more
    };
    //...and more
  }[];
  barCategoryGap?: number;
  //...and more
}

export const LevelLine: FC<Props> = ({
  formattedGraphicalItems,
  barCategoryGap,
  height,
  viewMode,
}) => {
  const firstSeries = formattedGraphicalItems && formattedGraphicalItems[0];
  const data: DataType[] = firstSeries?.props?.data as DataType[];
  const isMonthViewMode = viewMode === ChartViewMode.Month;

  if (!(formattedGraphicalItems && barCategoryGap && height)) return null;

  const renderLine = data.map(
    (item: DataType, index: number, arr: DataType[]) => {
      const nextIndex = index + 1;
      if (nextIndex >= arr.length) return false;
      const { x, y, width, level } = arr[nextIndex];
      const lineX = x + width + (width * barCategoryGap) / 100;
      const transform = isMonthViewMode ? `rotate(-90, ${lineX}, 25)` : '';
      return (
        item.level !== level && (
          <g key={x}>
            <text
              x={lineX - (isMonthViewMode ? 15 : 0)}
              y={isMonthViewMode ? 25 : 8}
              fill={colors.primary}
              textAnchor="middle"
              dominantBaseline="middle"
              fontWeight={400}
              transform={transform}
            >
              {__('views.user.account.shortcutOfLevel', { level })}
            </text>
            <Cross
              y={y}
              x={lineX + 7}
              top={25}
              left={lineX}
              height={height - 50}
              stroke={colors.gray}
              strokeDasharray="5"
            />
          </g>
        )
      );
    }
  );

  return <>{renderLine}</>;
};
