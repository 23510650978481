import styled from 'styled-components';

export const BoxContainer = styled('div')(
  ({ theme: { colors, spaces, boxShadows } }) => `
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 54px;
  gap: ${spaces['4xl']};
  width: 100%;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${boxShadows.basic};
`
);
