import React from 'react';

import { useMediaQuery } from 'hooks';
import { Button, Flex, Text } from 'components/common';

import { ResultContainer } from '../styles';

interface SingleGameEndProps {
  action?: () => void;
}

export const SingleGameEnd: React.FC<SingleGameEndProps> = ({ action }) => {
  const { isGtLarge } = useMediaQuery();

  return (
    <ResultContainer>
      <Flex columnDirection gap={isGtLarge ? 'sm' : 'xs'}>
        <Text
          text="views.game.singleGameEndHeading"
          size={isGtLarge ? 'md' : 'base'}
          weight="semibold"
        />
        <Text
          text="views.game.singleGameEnd"
          size={isGtLarge ? 'base' : 'xs'}
        />
      </Flex>
      <Button
        variant="secondary"
        text="button.finish"
        uppercase
        onClick={action}
        dataCy="next-game"
      />
    </ResultContainer>
  );
};
