import { gtMedia } from 'config';
import styled from 'styled-components';

export const StyledSelect = styled.select(
  ({ theme: { radius, colors, fontSizes } }) => `
  width: 100%;
  padding: 14px 18px;
  border-radius: ${radius.md};
  background-color: ${colors.light};
   font-size: ${fontSizes.xs};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('/assets/images/icons/dropdown.svg');
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: center;
  background-color: transparent;
  border: 1px solid ${colors.tertiaryBorder};

  ${gtMedia('sm')} {
    background-position-x: 97%;
  }

  ${gtMedia('md')} {
    background-position-x: 98%;
  }
`
);

export const Option = styled.option``;

export const Label = styled.label(
  ({ theme: { colors, fontSizes, fontWeight } }) => `
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeight.semibold};
  color: ${colors.primary};
`
);

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
