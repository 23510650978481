import React from 'react';

import { userRoutes } from 'config';
import UserSingleGame from 'views/User/Games/SingleGame';

const UserDashboard = React.lazy(() => import('views/User/Dashboard'));
const UserGames = React.lazy(() => import('views/User/Games'));
const UserGame = React.lazy(() => import('views/UserGame'));

export const UserRoutes = [
  {
    path: userRoutes.singleGame,
    element: <UserSingleGame />,
  },
  {
    path: userRoutes.games,
    element: <UserGames />,
  },
  {
    path: userRoutes.dashboard,
    element: <UserDashboard />,
  },
  {
    path: userRoutes.session,
    element: <UserGame />,
  },
];
