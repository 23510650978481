import { colors } from './colors';
import { fontSizes, lineHeights } from './fontSizes';
import { spaces, layoutHorizontalSpaces, layoutVerticalSpaces } from './spaces';
import { radius } from './radius';
import { breakPoints } from './breakPoints';
import { fontWeight } from './fontWeight';
import { sizes, boxSizes } from './sizes';
import { boxShadows } from './boxShadows';
import { iconSizes } from './iconSizes';

import { Theme } from 'types';

export const theme: Theme = {
  colors,
  fontSizes,
  lineHeights,
  spaces,
  radius,
  breakPoints,
  fontWeight,
  sizes,
  boxSizes,
  boxShadows,
  layoutHorizontalSpaces,
  layoutVerticalSpaces,
  iconSizes,
};
