import React from 'react';

import { paymentRoutes } from 'config';

const GetInByCode = React.lazy(() => import('views/Payment/GetInByCode'));
const AddCard = React.lazy(() => import('views/Payment/AddCard'));
const ChangeCard = React.lazy(() => import('views/Payment/ChangeCard'));
const PaymentFinished = React.lazy(() => import('views/Payment/Finished'));

export const PaymentRoutes = [
  {
    path: paymentRoutes.finished,
    element: <PaymentFinished />,
  },
  {
    path: paymentRoutes.getInByCode,
    element: <GetInByCode />,
  },
  {
    path: paymentRoutes.addCard,
    element: <AddCard />,
  },
  {
    path: paymentRoutes.changeCard,
    element: <ChangeCard />,
  },
];
