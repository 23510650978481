import styled from 'styled-components';

export const ListContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.base};
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: ${spaces['2xl']} 0;
`
);
