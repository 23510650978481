import styled from 'styled-components';
import { Text } from '../../Text';
import { gtMedia } from 'config';

interface NavItemContainerProps {
  active: boolean;
}

interface NavItemTextProps {
  active: boolean;
}

export const NavItemContainer = styled('button')<NavItemContainerProps>(
  ({ theme, active }) => `
  display: flex;
  align-items: center;
  gap: ${theme.spaces.xs};
  min-height: ${theme.sizes.md};
  width: 100%;
  border: none;
  box-shadow: ${theme.boxShadows.basic};
  background-color: ${
    active ? theme.colors.secondaryBackground : 'transparent'
  };
  padding: ${theme.spaces.sm} ${theme.spaces.sm};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.secondaryBackground};

    ${NavItemText} {
      font-weight: ${theme.fontWeight.bold};
    }
  }
`
);

export const NavItemText = styled(Text)<NavItemTextProps>(
  ({ active, theme: { fontSizes } }) => `
  font-weight: ${active ? '700' : '500'};
  font-size: ${fontSizes.xs};

  ${gtMedia('lg')} {
    font-size: ${fontSizes.base};
  }
`
);
