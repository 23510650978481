import styled from 'styled-components';

export const StyledContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['lg']};
  width: 100%;
  max-width: 700px;
  background-color: ${colors.light};
  margin: 0 auto;
`
);

export const InputsGroup = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.base};
  width: 100%;
`
);

export const ButtonContainer = styled.div`
  margin-top: auto;
`;
