import React from 'react';

import {
  BadgeInfo,
  Confirmation,
  DeleteFile,
  Info,
  Instruction,
  SessionSaveError,
  SessionUnavailableError,
  SubscriptionExpiring,
  SubscriptionPackage,
} from 'components/modals';
import { ModalType } from 'types';

interface Props {
  content: ModalType | null;
}

const ModalSelector: React.FC<Props> = ({ content }) => {
  switch (content) {
    case ModalType.Instruction:
      return <Instruction />;
    case ModalType.Info:
      return <Info />;
    case ModalType.Confirmation:
      return <Confirmation />;
    case ModalType.DeleteFile:
      return <DeleteFile />;
    case ModalType.BadgeInfo:
      return <BadgeInfo />;
    case ModalType.SessionUnavailableError:
      return <SessionUnavailableError />;
    case ModalType.SaveSessionError:
      return <SessionSaveError />;
    case ModalType.SubscriptionExpiring:
      return <SubscriptionExpiring />;
    case ModalType.PaymentPackage:
      return <SubscriptionPackage />;
    default:
      return null;
  }
};

export default ModalSelector;
