import { gtMedia } from 'config';
import styled from 'styled-components';

interface Props {
  isSelected: boolean;
  isCorrect: boolean;
  isWon: boolean;
}

export const StyledImage = styled.img<Props>(
  ({ theme: { radius, colors }, isSelected, isWon, isCorrect }) => `
  box-sizing: content-box;
  width: 74px;
  height: 74px;
  border-radius: ${radius.md};
  border: 3px solid transparent;
  cursor: pointer;
  ${isSelected && isCorrect && `border-color: ${colors.success}`};
  ${isSelected && !isCorrect && `border-color: ${colors.error}`};
  ${isWon && `border-color: ${colors.success}`};

  ${gtMedia('md')} {
    border-width: 4px;
    width: 96px;
    height: 96px;
  }

  ${gtMedia('lg')} {
    width: 110px;
    height: 110px;
  }


`
);
