import styled from 'styled-components';

import { gtMedia } from 'config';

interface Props {
  level: number;
}

const getVariant = (level: number) => {
  switch (level) {
    case 1:
      return `
        grid-template-columns: repeat(3, 1fr);
    `;
    case 2:
      return `
        grid-template-columns: repeat(4, 1fr);
      `;
    case 3:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 4:
      return `
        grid-template-columns: repeat(5, 1fr);
      `;
    case 5:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 6:
      return `
        grid-template-columns: repeat(6, 1fr);
      `;
    case 7:
      return `
      grid-template-columns: repeat(6, 1fr);
  `;
    case 8:
      return `
        grid-template-columns: repeat(5, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }
      `;
    case 9:
      return `
        grid-template-columns: repeat(6, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(8, 1fr);
        }
      `;
    case 10:
      return `
        grid-template-columns: repeat(6, 1fr);

        ${gtMedia('md')} {
          grid-template-columns: repeat(9, 1fr);
        }
      `;
  }
};

export const MemoryCardContainer = styled.div<Props>(
  ({ theme, level }) => `
  display: grid;
  justify-content: center;
  align-items: center;
  gap: ${theme.spaces.xs};
  margin: ${theme.spaces['2xl']} 0;
  ${getVariant(level)};

  ${gtMedia('md')} {
    gap: ${theme.spaces.sm};
    margin: 60px 0;
  }

  ${gtMedia('lg')} {
    gap: ${theme.spaces.base};
    margin: 72px 0;
  }

  ${gtMedia('lg')} {
    margin: 96px 0;
  }
`
);
