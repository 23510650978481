import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Spinner } from 'components/common';
import { useLang, useStripeActions } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { AddCardStripeForm } from './AddCardForm';
import { ChangeCardStripeForm } from './ChangeCardFrom';

interface StripeCardFormProps {
  type: 'change' | 'add';
}

export const StripeCardForm: FC<StripeCardFormProps> = ({ type }) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(
    Promise.resolve(null)
  );
  const { lang } = useLang();
  const {
    clientSecret,
    customerId,
    createSetupIntent,
    getPublishKey,
    getSubscriptionPrices,
    createChangeSetupIntent,
  } = useStripeActions();

  const setPublishKey = async () => {
    const res = await getPublishKey();
    if (res) {
      const { stripePublishKey } = res;
      setStripePromise(loadStripe(stripePublishKey));
    }
  };

  useEffect(() => {
    setPublishKey();
    if (type === 'add') {
      createSetupIntent();
    }
    if (type === 'change') {
      createChangeSetupIntent();
    }
    getSubscriptionPrices();
  }, []);

  if (!stripePromise || !clientSecret || !customerId) return <Spinner />;

  return (
    <>
      {stripePromise && clientSecret && customerId && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, locale: lang }}
        >
          {type === 'add' && <AddCardStripeForm />}
          {type === 'change' && <ChangeCardStripeForm />}
        </Elements>
      )}
    </>
  );
};
