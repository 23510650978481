import React from 'react';
import { useDrag } from 'react-dnd';
import { Preview } from 'react-dnd-multi-backend';

import { apiUrl } from 'config';
import {
  CollectionItemType,
  AccessibleCollectionItem,
} from 'cogamika-back/types';

import { BoxContainer, BoxImage } from './styles';

interface Props {
  image: AccessibleCollectionItem;
  canDrag: boolean;
}

export const Box: React.FC<Props> = ({ image, canDrag }) => {
  const generatePreview = ({ style }: any) => {
    return (
      <div style={{ ...style, opacity: 0.5 }}>
        <BoxImage
          src={`${apiUrl}file/${image.description}`}
          data-testid="box"
          effect="opacity"
        />
      </div>
    );
  };

  const [, drag] = useDrag(
    () => ({
      type: CollectionItemType.Image,
      item: { collectionType: image.collectionType },
      canDrag,
    }),
    [image]
  );

  return (
    <>
      <BoxContainer>
        {canDrag ? (
          <div ref={drag}>
            <BoxImage
              src={`${apiUrl}file/${image.description}`}
              data-testid="box"
              effect="opacity"
            />
          </div>
        ) : (
          <BoxImage
            src={`${apiUrl}file/${image.description}`}
            data-testid="box"
          />
        )}
      </BoxContainer>
      <Preview generator={generatePreview} />
    </>
  );
};
