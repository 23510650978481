import styled from 'styled-components';

import { gtMedia } from 'config';

export const StyledNavigation = styled.nav(
  ({ theme: { colors } }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.secondaryBackground};
  padding: 16px 24px;

  ${gtMedia('md')} {
    padding: 24px 96px;
  }

  ${gtMedia('lg')} {
    padding: 24px 144px;
  }

  ${gtMedia('xl')} {
    padding: 34px 330px;
  }
`
);
