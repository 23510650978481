import React, { useEffect } from 'react';
import { Navigate, useRoutes, Outlet } from 'react-router-dom';

import { UserRole } from 'cogamika-back/types';
import {
  UserRoutes,
  AdminRoutes,
  RequireAuthAndPaid,
  SuperAdminRoutes,
  PaymentRoutes,
  UserNotPaidRoutes,
} from 'components/routes';
import { getUserDetails } from 'slices';
import { useAppDispatch, useAppSelector, useLang } from 'hooks';

import {
  routes,
  superAdminRoutes,
  adminRoutes,
  userRoutes,
  paymentRoutes,
  userNotPaidRoutes,
} from 'config';

const Login = React.lazy(() => import('views/Login'));
const Register = React.lazy(() => import('views/Register'));
const Page404 = React.lazy(() => import('views/Page404'));
const Home = React.lazy(() => import('views/Home'));
const ActivateUser = React.lazy(() => import('views/ActivateUser'));
const Subscriptions = React.lazy(() => import('views/Subscriptions'));
const FreeGame = React.lazy(() => import('views/FreeGame/Game'));
const ResetPassword = React.lazy(() => import('views/ResetPassword'));
const SetPassword = React.lazy(() => import('views/SetPassword'));
const RegisterConfirmation = React.lazy(
  () => import('views/RegisterConfirmation')
);

export const BaseRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setDefaultUserLanguage } = useLang();
  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    setDefaultUserLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const router = useRoutes([
    {
      path: '*',
      element: <Navigate to="404" replace />,
    },
    {
      path: routes.freeGame,
      element: <FreeGame />,
    },
    {
      path: routes.root,
      element: <Home />,
    },
    {
      path: routes.activateUser,
      element: <ActivateUser />,
    },
    {
      path: routes.login,
      element: <Login />,
    },
    {
      path: routes.register,
      element: <Register />,
    },
    {
      path: routes.subscriptions,
      element: <Subscriptions />,
    },
    {
      path: routes.resetPassword,
      element: <ResetPassword />,
    },
    {
      path: routes.setPassword,
      element: <SetPassword />,
    },
    {
      path: routes.registerConfirmation,
      element: <RegisterConfirmation />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: superAdminRoutes.root,
      element: (
        <RequireAuthAndPaid
          authRoles={[UserRole.SuperAdmin]}
          paidRoles={[UserRole.SuperAdmin, UserRole.Admin]}
        >
          <Outlet />
        </RequireAuthAndPaid>
      ),
      children: SuperAdminRoutes,
    },
    {
      path: adminRoutes.root,
      element: (
        <RequireAuthAndPaid
          authRoles={[UserRole.Admin]}
          paidRoles={[UserRole.SuperAdmin, UserRole.Admin]}
        >
          <Outlet />
        </RequireAuthAndPaid>
      ),
      children: AdminRoutes,
    },
    {
      path: userRoutes.root,
      element: (
        <RequireAuthAndPaid
          authRoles={[UserRole.User]}
          paidRoles={[UserRole.SuperAdmin, UserRole.Admin]}
        >
          <Outlet />
        </RequireAuthAndPaid>
      ),
      children: UserRoutes,
    },
    {
      path: userNotPaidRoutes.root,
      element: (
        <RequireAuthAndPaid authRoles={[UserRole.User]}>
          <Outlet />
        </RequireAuthAndPaid>
      ),
      children: UserNotPaidRoutes,
    },
    {
      path: paymentRoutes.root,
      element: (
        <RequireAuthAndPaid authRoles={[UserRole.User]}>
          <Outlet />
        </RequireAuthAndPaid>
      ),
      children: PaymentRoutes,
    },
  ]);

  return router;
};
