import React from 'react';

import { QuestionAnswerData, QuestionAnswerType } from 'cogamika-back/types';
import { apiUrl } from 'config';
import { useMediaQuery } from 'hooks';
import { Text } from 'components/common';

import { StyledImage } from './styles';

interface Props {
  question: QuestionAnswerData;
}

export const Question: React.FC<Props> = ({ question }) => {
  const { isGtLarge } = useMediaQuery();

  const getQuestion = (type: QuestionAnswerType) => {
    switch (type) {
      case QuestionAnswerType.Text:
        return (
          <Text
            text={question.description}
            noTranslate
            size={!isGtLarge ? 'lg' : '2xl'}
            weight="semibold"
          />
        );
      case QuestionAnswerType.Image:
        return (
          <StyledImage
            src={`${apiUrl}file/${question.description}`}
            effect="opacity"
          />
        );
      default:
        return null;
    }
  };
  return <>{getQuestion(question.type)}</>;
};
