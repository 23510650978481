import styled from 'styled-components';
import { gtMedia } from 'config';

export const StyledContainer = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces['lg']};
  width: 100%;
  background-color: ${colors.light};
  margin: 0 auto;

  ${gtMedia('sm')} {
    max-width: 624px
  }
`
);

export const InputsGroup = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  flex-direction: column;
  gap: ${spaces.base};
  width: 100%;
`
);

export const TextContainer = styled.div`
  width: 90%;
`;
