import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserRole, UserStatus } from 'cogamika-back/types';
import { IconButton, LinkButton, NavLink } from 'components/common';
import {
  adminRoutes,
  routes,
  superAdminRoutes,
  userNotPaidRoutes,
  userRoutes,
} from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { logout } from 'slices';
import { AccountSubPagesRoute, IconName } from 'types';

import {
  CloseMenuWrapper,
  MenuBtnWrapper,
  MobileNavigationSection,
  NavigationSection,
  StyledLinksContainer,
} from '../styles';

interface Props {
  role: UserRole;
}

interface NavLinkType {
  iconName: IconName;
  to: string;
  text: string;
  unpaidDisabled?: boolean;
}

const getLinks = (role: UserRole): NavLinkType[] => {
  switch (role) {
    case UserRole.SuperAdmin:
      return [
        {
          iconName: 'games',
          to: superAdminRoutes.dashboard,
          text: 'button.games',
        },
        {
          iconName: 'folder',
          to: superAdminRoutes.files,
          text: 'button.files',
        },
        {
          iconName: 'settings',
          to: superAdminRoutes.settings,
          text: 'button.settings',
        },
      ];
    case UserRole.Admin:
      return [
        {
          iconName: 'games',
          to: adminRoutes.dashboard,
          text: 'button.games',
        },
        {
          iconName: 'folder',
          to: adminRoutes.files,
          text: 'button.files',
        },
        {
          iconName: 'settings',
          to: adminRoutes.settings,
          text: 'button.settings',
        },
      ];
    case UserRole.User:
      return [
        {
          iconName: 'profile',
          to: userNotPaidRoutes.account,
          text: 'button.profile',
        },
        {
          iconName: 'games',
          to: userRoutes.games,
          text: 'button.games',
          unpaidDisabled: true,
        },
        {
          iconName: 'help',
          to: `${userNotPaidRoutes.account}/${AccountSubPagesRoute.Help}`,
          text: 'button.help',
        },
      ];
    default:
      return [];
  }
};

export const AuthLinks: React.FC<Props> = ({ role }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const links = getLinks(role);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  const logoutHandle = async () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        navigate(routes.root);
      });
  };

  const toggleNavigation = (show: boolean) => {
    setShowMobileNav(show);
  };

  return (
    <StyledLinksContainer>
      <NavigationSection>
        {links.map((link) => (
          <NavLink
            key={link.text}
            iconName={link.iconName}
            to={link.to}
            text={link.text}
            disabled={
              link.unpaidDisabled && !!user && user.status !== UserStatus.Paid
            }
          />
        ))}
        <LinkButton
          text="button.logout"
          uppercase
          onClick={logoutHandle}
          iconName="logout"
        />
      </NavigationSection>

      <MenuBtnWrapper>
        <IconButton
          name="menu"
          onClick={() => toggleNavigation(true)}
          type="button"
          size="sm"
        />
      </MenuBtnWrapper>

      <MobileNavigationSection isMobileNavOpen={showMobileNav}>
        <CloseMenuWrapper>
          <IconButton
            name="cross"
            onClick={() => toggleNavigation(false)}
            type="button"
            size="sm"
          />
        </CloseMenuWrapper>
        {links.map((link) => (
          <NavLink
            key={link.text}
            iconName={link.iconName}
            to={link.to}
            text={link.text}
            disabled={
              link.unpaidDisabled && !!user && user.status !== UserStatus.Paid
            }
          />
        ))}
        <LinkButton
          text="button.logout"
          uppercase
          onClick={logoutHandle}
          iconName="logout"
        />
      </MobileNavigationSection>
    </StyledLinksContainer>
  );
};
