import { useWindowSize } from 'hooks/useWindowSize';

import { breakPoints } from 'config/theme/basicStyleValues/breakPoints';

export const useMediaQuery = () => {
  const { width } = useWindowSize();

  const isSmallScreen = width && parseInt(breakPoints['sm']) > width;
  const isMediumScreen =
    width &&
    parseInt(breakPoints['sm']) <= width &&
    parseInt(breakPoints['md']) > width;
  const isLaptopScreen =
    width &&
    parseInt(breakPoints['md']) <= width &&
    parseInt(breakPoints['lg']) > width;
  const isBigScreen = width && parseInt(breakPoints['lg']) <= width;

  const isGtSmall = width && parseInt(breakPoints['sm']) <= width;
  const isGtMedium = width && parseInt(breakPoints['md']) <= width;
  const isGtLarge = width && parseInt(breakPoints['lg']) <= width;

  return {
    isSmallScreen: !!isSmallScreen,
    isMediumScreen: !!isMediumScreen,
    isLaptopScreen: !!isLaptopScreen,
    isBigScreen: !!isBigScreen,
    isGtSmall: !!isGtSmall,
    isGtMedium: !!isGtMedium,
    isGtLarge: !!isGtLarge,
  };
};
