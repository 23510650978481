import styled from 'styled-components';

import { gtMedia } from 'config';

export const ResultContainer = styled.div(
  ({ theme: { spaces, colors, boxSizes } }) => `
  width: ${boxSizes.confirmModalBox};
  display: flex;
  flex-direction: column;
  padding: ${spaces.lg};
  gap: ${spaces.base};
  background-color: ${colors.secondaryBackground};

  ${gtMedia('lg')} {
    max-width: ${boxSizes.confirmModalBoxLarge};
    padding: ${spaces['6xl']};
    gap: ${spaces['2xl']};
  }
`
);

export const Background = styled.div(
  ({ theme: { spaces, colors } }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  padding: ${spaces.xl};
  background-color: ${colors.gray};
`
);
