import styled from 'styled-components';

import { gtMedia } from 'config';
import { QuestionAnswerType } from 'cogamika-back/types';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${spaces.lg};
    padding: 32px 24px;
    justify-content: center;
    box-sizing: content-box;

    ${gtMedia('md')} {
      width: 620px
    }

    ${gtMedia('lg')} {
      width: 876px;
      gap: ${spaces['2xl']};
    }
  `
);

export const AnswersContainer = styled.div(
  ({ theme: { spaces } }) => `
    display: flex;
    flex-direction: column;
    gap: ${spaces.base};

    ${gtMedia('lg')} {
      gap: ${spaces.lg};
    }
  `
);

interface AnswersGridProps {
  variant: QuestionAnswerType;
}

const getVariant = (variant: QuestionAnswerType) => {
  switch (variant) {
    case QuestionAnswerType.Image:
      return `
        grid-template-columns: repeat(4, 1fr);

      `;
    case QuestionAnswerType.Text:
      return `
      grid-template-columns: repeat(2, 1fr);
    `;
  }
};

export const AnswersGrid = styled.div<AnswersGridProps>(
  ({ theme: { spaces }, variant }) => `
    display: grid;
    justify-content: center;
    align-items: center;
    gap: ${spaces.sm};

    ${getVariant(variant)};
  `
);
