import { PaymentResult } from 'itfpay';
import { subscriptionsData } from 'config';
import { getDiscountedPrice } from 'utils';
import { PromoRes, SubscribePlans, UserPayReq } from 'cogamika-back/types';
import { useApi } from './useApi';
import { useAppSelector } from './redux';
import { useState } from 'react';
import { useAnalytics } from './useAnalytics';

interface UsePayment {
  pay: (subscriptionPackage: SubscribePlans, data: UserPayReq) => Promise<void>;
  checkPromoCode: (code: string, plan: SubscribePlans) => Promise<void>;
  checkGiftCode: (code: string) => Promise<void>;
  promo: PromoRes | undefined;
  isValidCode: boolean;
}

type PayOptions = {
  promoCode?: string;
};

export const usePayment = (): UsePayment => {
  const { post, get } = useApi();
  const { sendPaymentEvent } = useAnalytics();
  const [isValidCode, setIsValidCode] = useState<boolean>(true);
  const [promo, setPromo] = useState<PromoRes>();
  const user = useAppSelector((state) => state.user.user);

  const checkPromoCode = async (code: string, plan: SubscribePlans) => {
    setIsValidCode(true);
    const result = await get<PromoRes>(`promo/check/${code}/${plan}`);

    if (!result) {
      setIsValidCode(false);
      setPromo(undefined);
    } else {
      setIsValidCode(true);
      setPromo(result);
    }
  };

  const checkGiftCode = async (code: string) => {
    setIsValidCode(true);
    const result = await get<PromoRes>(`promo/check/${code}`);

    if (!result) {
      setIsValidCode(false);
      setPromo(undefined);
    } else {
      setIsValidCode(true);
      setPromo(result);
    }
  };

  const payMonth = async (options?: PayOptions) => {
    let promo: PromoRes | void | null = null;
    if (options?.promoCode) {
      promo = await get<PromoRes>(
        `promo/check/${options.promoCode}/${SubscribePlans.Month}`
      );
    }

    const result = await post<PaymentResult, UserPayReq>(
      `user/payment/basic/${user?.id}`,
      { ...options }
    );

    if (result && result.redirectUrl) {
      const { redirectUrl } = result;
      const price = subscriptionsData.MONTH.price;
      const value = promo ? getDiscountedPrice(promo, price) : price;
      const coupon = options?.promoCode || 'none';
      const transaction_id = `${
        (user?.id || '') + new Date().getMilliseconds()
      }`;
      sendPaymentEvent({
        currency: 'PLN',
        transaction_id,
        value,
        coupon,
        items: [
          {
            item_name: SubscribePlans.Month,
          },
        ],
      });
      window.location.replace(redirectUrl);
    }
  };

  const payHalfYear = async (options?: PayOptions) => {
    let promo: PromoRes | void | null = null;
    if (options?.promoCode) {
      promo = await get<PromoRes>(
        `promo/check/${options.promoCode}/${SubscribePlans.HalfYear}`
      );
    }

    const result = await post<PaymentResult, UserPayReq>(
      `user/payment/standard/${user?.id}`,
      { ...options }
    );

    if (result && result.redirectUrl) {
      const { redirectUrl } = result;
      const price = subscriptionsData.HALF_YEAR.price;
      const value = promo ? getDiscountedPrice(promo, price) : price;
      const coupon = options?.promoCode || 'none';
      const transaction_id = `${
        (user?.id || '') + new Date().getMilliseconds()
      }`;
      sendPaymentEvent({
        currency: 'PLN',
        transaction_id,
        value,
        coupon,
        items: [
          {
            item_name: SubscribePlans.HalfYear,
          },
        ],
      });
      window.location.replace(redirectUrl);
    }
  };

  const payYear = async (options?: PayOptions) => {
    let promo: PromoRes | void | null = null;
    if (options?.promoCode) {
      promo = await get<PromoRes>(
        `promo/check/${options.promoCode}/${SubscribePlans.Year}`
      );
    }

    const result = await post<PaymentResult, UserPayReq>(
      `user/payment/premium/${user?.id}`,
      { ...options }
    );

    if (result && result.redirectUrl) {
      const { redirectUrl } = result;
      const price = subscriptionsData.YEAR.price;
      const value = promo ? getDiscountedPrice(promo, price) : price;
      const coupon = options?.promoCode || 'none';
      const transaction_id = `${
        (user?.id || '') + new Date().getMilliseconds()
      }`;
      sendPaymentEvent({
        currency: 'PLN',
        transaction_id,
        value,
        coupon,
        items: [
          {
            item_name: SubscribePlans.Year,
          },
        ],
      });
      window.location.replace(redirectUrl);
    }
  };

  const pay = async (subscriptionPackage: SubscribePlans, data: UserPayReq) => {
    let body: UserPayReq = {};
    const { promoCode } = data;

    if (promoCode) body = { promoCode };

    switch (subscriptionPackage) {
      case SubscribePlans.Month:
        payMonth(body);
        break;
      case SubscribePlans.HalfYear:
        payHalfYear(body);
        break;
      case SubscribePlans.Year:
        payYear(body);
        break;
    }
  };

  return {
    pay,
    checkPromoCode,
    checkGiftCode,
    promo,
    isValidCode,
  };
};
