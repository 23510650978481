import { SubscribePlans } from '../../types/user';
import { BasePaginationWithSearch } from '../base-classes';

export enum DiscountType {
  Percent,
  SetPrice,
  Discount,
}

export interface PromoEntity {
  code: string;
  validTo: Date;
  uses: number;
  value: number;
  type: DiscountType;
}

export interface ListPromoReq extends BasePaginationWithSearch {
  type?: DiscountType;
}

export interface AddPromoReq {
  code: string;
  type: DiscountType;
  validTo: string | Date;
  value: number;
  plan?: SubscribePlans;
}

export interface EditPromoReq {
  code?: string;
  type?: DiscountType;
  validTo?: string | Date;
  value?: number;
  plan?: SubscribePlans;
}

export interface SuperAdminPromoRes {
  id: string;
  code: string;
  type: DiscountType;
  validTo: string;
  value: number;
  plan: SubscribePlans;
}

export interface PromoRes {
  id: string;
  type: DiscountType;
  value: number;
  plan: SubscribePlans;
}
