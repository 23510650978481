import { FC } from 'react';

import { useMediaQuery } from 'hooks';
import { AccountSubPages, IconName } from 'types';

import { MobileNav } from './MobileNav';
import { DesktopNav } from './DesktopNav';

export interface NavItem {
  text: string;
  icon: IconName;
  noTranslate?: boolean;
}

interface SidePanelNavProps {
  items: NavItem[];
  activeItem: number;
  onChangePage: (pageIndex: number) => void;
}

export const SidePanelNav: FC<SidePanelNavProps> = ({
  items,
  activeItem = AccountSubPages.Results,
  onChangePage,
}) => {
  const { isSmallScreen, isMediumScreen } = useMediaQuery();

  if (isSmallScreen || isMediumScreen) {
    return (
      <MobileNav
        items={items}
        onChangePage={onChangePage}
        activeItem={activeItem}
      />
    );
  } else {
    return (
      <DesktopNav
        items={items}
        onChangePage={onChangePage}
        activeItem={activeItem}
      />
    );
  }
};
