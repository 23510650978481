import React from 'react';

import { IconName } from 'types';
import { Icon, TranslateText } from 'components/common';

import { StyledNavLink } from './styles';

interface Props {
  iconName?: IconName;
  to: string;
  text: string;
  disabled?: boolean;
}

export const NavLink: React.FC<Props> = ({
  iconName,
  to,
  text,
  disabled = false,
}) => {
  return (
    <StyledNavLink disablebtn={disabled} to={to}>
      {iconName && <Icon name={iconName} />}
      <TranslateText text={text} />
    </StyledNavLink>
  );
};
