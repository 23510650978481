import styled from 'styled-components';

import { gtMedia } from 'config';
import { gamePadding } from 'mixins';
import { GridContainer, GridItem } from 'components/common';

export const InstructionContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-bottom: 32px;
  ${gamePadding};

  ${gtMedia('md')} {
    padding: 0 96px 32px;
  }

  ${gtMedia('lg')} {
    padding: 0 144px;
  }

  ${gtMedia('xl')} {
    padding: 0 330px;
  }
`;

export const InstructionGridContainer = styled(GridContainer)`
  align-items: flex-start;
`;

export const GridItemVideo = styled(GridItem)`
  place-self: flex-start;
`;

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  ${gtMedia('lg')} {
    min-height: 200px;
  }
`;
