import styled from 'styled-components';

import { ChartViewMode } from 'cogamika-back/types';

export const StyledSelect = styled.select(
  ({ theme: { radius, colors, fontSizes } }) => `
    width: 100%;
    padding: 14px 30px 14px 0px;
    border-radius: ${radius.md};
    background-color: ${colors.light};
    font-size: ${fontSizes.base};
    color: ${colors.secondaryGray};
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('/assets/images/icons/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-color: transparent;
    border: none;
  `
);

export const Option = styled.option`
  text-align: center;
`;

export const SwitchContainer = styled.div<{ activeButton: ChartViewMode }>(
  ({ theme: { colors }, activeButton }) => `
    display: flex;
    border: 1px solid ${colors.secondaryBorder};
    background: ${colors.lightGray};
    border-radius: 8px;
    position: relative; overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: white;
      border-radius: 8px;
      transition: transform 0.5s ease, border 0.5s ease;
      border-right: ${
        activeButton === ChartViewMode.Month
          ? `1px solid ${colors.secondaryBorder}`
          : ''
      };
      border-left: ${
        activeButton === ChartViewMode.Week
          ? `1px solid ${colors.secondaryBorder}`
          : ''
      };
      transform: ${
        activeButton === ChartViewMode.Month
          ? 'translateX(0)'
          : 'translateX(100%)'
      };
      z-index: 0;
    }
  `
);

interface ButtonProps {
  isSelected: boolean;
}

export const ButtonMonth = styled.button<ButtonProps>(
  ({ isSelected }) => `
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    background: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    ${isSelected ? 'font-weight: 500;' : ''}
  `
);

export const ButtonWeek = styled.button<ButtonProps>(
  ({ isSelected }) => `
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    background: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
    ${isSelected ? 'font-weight: 500;' : ''}
  `
);
