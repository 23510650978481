import { FC } from 'react';
import { __ } from 'utils/i18n';
import { ChartViewMode } from 'cogamika-back/types';

import { SwitchContainer, ButtonMonth, ButtonWeek } from './styles';

interface ViewModeSelectProps {
  onChange: (newValue: ChartViewMode) => void;
  mode: ChartViewMode;
}

export const Switch: FC<ViewModeSelectProps> = ({ onChange, mode }) => {
  return (
    <SwitchContainer activeButton={mode}>
      <ButtonMonth
        onClick={() => {
          if (mode === ChartViewMode.Month) return;

          onChange(ChartViewMode.Month);
        }}
        isSelected={mode === ChartViewMode.Month}
      >
        {__('enum.month')}
      </ButtonMonth>
      <ButtonWeek
        onClick={() => {
          if (mode === ChartViewMode.Week) return;
          onChange(ChartViewMode.Week);
        }}
        isSelected={mode === ChartViewMode.Week}
      >
        {__('enum.week')}
      </ButtonWeek>
    </SwitchContainer>
  );
};
