import styled from 'styled-components';

import { gtMedia } from 'config';

export const TrainingEndedContainer = styled.div(
  ({ theme: { spaces, colors, boxSizes } }) => `
  max-width: ${boxSizes.confirmModalBox};
  display: flex;
  flex-direction: column;
  gap: ${spaces.base};
  padding: ${spaces.lg};
  background-color: ${colors.secondaryBackground};


   ${gtMedia('lg')} {
    max-width: ${boxSizes.confirmModalBoxLarge};
    padding: ${spaces['6xl']};
    gap: ${spaces.lg};
  }
`
);
