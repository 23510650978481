import React, { useMemo } from 'react';

import { useMediaQuery } from 'hooks';
import { getLetterByIndex } from 'utils';
import { Button, Flex, Icon, Text } from 'components/common';

import { ResultContainer } from '../styles';

interface Props {
  handleNext: () => void;
  correctAnswerIndex: number;
}

export const Failure: React.FC<Props> = ({
  handleNext,
  correctAnswerIndex,
}) => {
  const { isGtLarge } = useMediaQuery();
  const correctAnswer = useMemo(
    () => getLetterByIndex(correctAnswerIndex),
    [correctAnswerIndex]
  );

  return (
    <ResultContainer variant="failure">
      <Flex alignItems="center" gap="base">
        <Icon
          name={!isGtLarge ? 'error-check-mobile' : 'error-check-desktop'}
        />
        <Text
          text="views.survey.incorrectAnswer"
          size={!isGtLarge ? 'xs' : 'base'}
          weight="semibold"
          color="error"
          translationArgs={{ correctAnswer }}
        />
      </Flex>
      <Button
        text="button.next"
        variant="outlined-error"
        uppercase
        iconName="right-arrow"
        onClick={handleNext}
        dataCy="nextQuestion"
      />
    </ResultContainer>
  );
};
