import { Button, GridContainer } from 'components/common';
import { Form, TextInput } from 'components/forms';
import styled from 'styled-components';

export const StyledGridContainer = styled(GridContainer)(
  ({ theme }) => `
  width: 100%;
  height: 100%;
`
);

export const StyledInput = styled(TextInput)(
  () => `
  background-color: transparent;
`
);

export const SubscriptionButton = styled(Button)(
  ({ theme: { colors } }) => `
  background-color: transparent;
  text-transform: uppercase;

  &:hover::before {
    border-color: ${colors.secondaryHover};
  }

  &::before {
    margin-right: -5px; 
    display: block;
    content: "";
    border: solid ${colors.primary};
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
`
);

export const StyledForm = styled(Form)(
  () => `
  display: flex;
  flex-direction: column;
  gap: 20px;
`
);
