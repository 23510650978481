import styled from 'styled-components';

import { gtMedia } from 'config';

export const StyledNavigation = styled.nav(
  ({ theme: { colors } }) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.secondaryBackground};
  padding: 26px 24px;
  z-index: 999;

  ${gtMedia('sm')} {
    padding: 26px 72px;
  }

  ${gtMedia('md')} {
    padding: 26px 96px;
  }

  ${gtMedia('lg')} {
    padding: 26px 144px;
  }

  ${gtMedia('xl')} {
    padding: 26px 288px;
  }
`
);

export const StyledLinksContainer = styled.div(
  ({ theme: { spaces } }) => `
  display: flex;
  align-items: center;
  gap: ${spaces['2xl']};
`
);

export const NavigationSection = styled.div(
  ({ theme: { spaces } }) => `
  display: none;
  gap: ${spaces['lg']};

  ${gtMedia('md')} {
    display: flex
  }
`
);

interface Props {
  isMobileNavOpen: boolean;
}

export const MobileNavigationSection = styled.div<Props>(
  ({ theme, isMobileNavOpen }) => `
    padding: 176px 52px 0;
    display: flex;
    flex-direction: column;
    gap: 36px;
    position: fixed;
    top: 0;
    background-color: #fff;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: ${theme.colors.secondaryBackground};
    transform: translateX(${isMobileNavOpen ? '0' : '100%'});
    transition: transform 0.5s ease-in-out;
    z-index: 999;

    &.open {
      transform: translateX(0);
    }

    @media (orientation: landscape) {
      padding-top: 100px;
    }
  `
);

export const MenuBtnWrapper = styled.div`
  display: block;

  ${gtMedia('md')} {
    display: none;
  }
`;

export const CloseMenuWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 24px;
`;
