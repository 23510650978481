import {
  ChangeCardReq,
  CreateChangeSetupIntentRes,
  CreateSetupIntentRes,
  CreateSubscriptionReq,
  CreateSubscriptionRes,
  GetStripeConfigRes,
  GetSubscriptionPricesRes,
  UserStripeInfoRes,
} from 'cogamika-back/types';
import { StripeActionsContext } from 'context';
import { useContext } from 'react';
import { useApi } from './useApi';

interface UseStripeActions {
  priceIds: string[];
  clientSecret: string;
  customerId: string;
  pauseSubscription: () => Promise<void>;
  resumeSubscription: () => Promise<void>;
  changeCard: (data: ChangeCardReq) => Promise<void>;
  getPublishKey: () => Promise<GetStripeConfigRes | null>;
  getUserStripeInfo: () => Promise<UserStripeInfoRes | null>;
  getSubscriptionPrices: () => Promise<void>;
  createSubscription: (
    data: CreateSubscriptionReq
  ) => Promise<CreateSubscriptionRes | null>;
  createSetupIntent: () => Promise<void>;
  createChangeSetupIntent: () => Promise<void>;
  inProgress: boolean;
}

export const useStripeActions = (): UseStripeActions => {
  const stripeActionContext = useContext(StripeActionsContext);
  const {
    clientSecret,
    customerId,
    priceIds,
    setPriceIds,
    setClientSecret,
    setCustomerId,
  } = stripeActionContext;
  const { get, post, inProgress } = useApi();

  if (stripeActionContext === undefined) {
    throw new Error(
      'useStripeActions must be used within a StripeActionContext.Provider'
    );
  }

  const pauseSubscription = async () => {
    await post(`/stripe/subscriptions/pause-subscription`);
  };

  const resumeSubscription = async () => {
    await post(`/stripe/subscriptions/resume-subscription`);
  };

  const getPublishKey = async () => {
    const result = await get<GetStripeConfigRes>(`/stripe/config`);
    return result ?? null;
  };

  const createSetupIntent = async () => {
    const result = await post<CreateSetupIntentRes>(
      `/stripe/customers/create-setup-intent`
    );

    if (result) {
      const { clientSecret, customerId } = result;
      setClientSecret(clientSecret);
      setCustomerId(customerId);
    }
  };

  const createChangeSetupIntent = async () => {
    const result = await post<CreateChangeSetupIntentRes>(
      `/stripe/customers/create-change-setup-intent`
    );

    if (result) {
      const { clientSecret, customerId } = result;
      setClientSecret(clientSecret);
      setCustomerId(customerId);
    }
  };

  const createSubscription = async (data: CreateSubscriptionReq) => {
    const result = await post<CreateSubscriptionRes, CreateSubscriptionReq>(
      `/stripe/subscriptions/create-subscription`,
      data
    );
    return result ?? null;
  };

  const changeCard = async (data: ChangeCardReq) => {
    await post<void, ChangeCardReq>(`/stripe/customers/change-card`, data);
  };

  const getSubscriptionPrices = async () => {
    const result = await get<GetSubscriptionPricesRes>(
      `/stripe/subscriptions/subscription-prices`
    );
    if (result) {
      setPriceIds(result.map(({ id }) => id));
    }
  };

  const getUserStripeInfo = async () => {
    const result = await get<UserStripeInfoRes>(
      `/stripe/customers/get-user-info`
    );
    return result ?? null;
  };

  return {
    clientSecret,
    customerId,
    priceIds,
    pauseSubscription,
    resumeSubscription,
    changeCard,
    getSubscriptionPrices,
    getPublishKey,
    getUserStripeInfo,
    createSetupIntent,
    createChangeSetupIntent,
    createSubscription,
    inProgress,
  };
};
