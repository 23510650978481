import styled from 'styled-components';

export const StyledRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRadio = styled('input')(
  ({ theme }) => `
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: ${theme.colors.secondary};
  width: 20px;
  height: 20px;
  border: 0.15em solid ${theme.colors.secondary};
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  margin-right: ${theme.spaces.base};
  cursor: pointer;

  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${theme.colors.secondary};
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    cursor: not-allowed;
  }
`
);

export const StyledLabel = styled.label`
  height: 100%;
  font-size: 1rem;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;
