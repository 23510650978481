import { gtMedia } from 'config';
import styled from 'styled-components';

export const Container = styled.div(
  ({ theme: { spaces } }) => `
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: ${spaces.base};
  margin-bottom: ${spaces.xs};
  gap: ${spaces.sm};

  ${gtMedia('md')} {
    margin-top: 60px;
    margin-bottom: 48px;
  }
`
);

export const Line = styled.div(
  ({ theme: { colors, radius } }) => `
  width: 23px;
  height: 2px;
  background-color: ${colors.teritaryGray};
  border-radius: ${radius.md};
`
);
