import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLogo = styled.img``;

export const StyledLinkLogo = styled(Link)`
  display: flex;
  align-items: center;
  max-width: 116px;
`;
