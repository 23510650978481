import styled from 'styled-components';

import { gtMedia } from 'config';

export const Wrapper = styled('div')(
  ({ theme: { colors } }) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondaryBackground};
  padding: 24px;

  &::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 22, 24, 0.1);
  }
`
);

export const MainContainer = styled('div')(
  ({ theme: { colors, boxShadows } }) => `
  position: relative;
  background-color: ${colors.secondaryBackground};
  box-shadow: ${boxShadows.basic};
  max-height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px;
  overflow-y: scroll;

  ${gtMedia('md')} {
    padding: 32px 24px;
    max-width: 750px;
  }

  ${gtMedia('lg')} {
    padding: 48px
    max-width: 1000px;
  }
`
);

export const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  ${gtMedia('lg')} {
    min-height: 200px;
  }
`;
