import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { SetPassword, StatusRes } from 'cogamika-back/types';
import { Button, Flex, Heading, Spinner, Warning } from 'components/common';
import { Form, PasswordInput } from 'components/forms';
import { routes, setPasswordSchema } from 'config';
import { useApi, useAppSelector } from 'hooks';
import { ActivateUserFormType } from 'types';

import { InputsGroup, StyledContainer } from './styles';

export const SetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const { token, userId } = useParams();
  const { post, inProgress } = useApi();
  const { errorNotification, formValidationErrors } = useAppSelector(
    (state) => state.notifications
  );

  const methods = useForm<ActivateUserFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(setPasswordSchema),
  });
  const { isSubmitting } = methods.formState;
  const isButtonDisabled = isSubmitting;

  const submit = async (data: ActivateUserFormType) => {
    const res = await post<StatusRes, SetPassword>(
      `/auth/set-password/${userId}/${token}`,
      {
        password: data.password,
      }
    );

    if (res) {
      navigate(routes.login);
    }
  };

  return (
    <StyledContainer>
      {inProgress && <Spinner />}
      <Flex columnDirection gap="sm">
        <Heading
          text="views.setPassword.heading"
          size="base"
          weight="semibold"
        />
      </Flex>
      <Form submitHandler={submit} methods={methods}>
        <Flex columnDirection gap="3xl" fullWidth>
          <Flex columnDirection gap="base" fullWidth>
            <InputsGroup>
              <PasswordInput
                name="password"
                placeholder="createPassword"
                required
              />
              <PasswordInput
                name="repeatPassword"
                placeholder="repeatPassword"
                required
              />
            </InputsGroup>
            <Warning
              text={errorNotification?.text}
              isError={!!errorNotification || formValidationErrors.length > 0}
              formValidationErrors={formValidationErrors}
              noTranslate
            />
          </Flex>
          <Button
            variant="primary"
            text="button.next"
            type="submit"
            fullWidth
            uppercase
            disabled={isButtonDisabled}
          />
        </Flex>
      </Form>
    </StyledContainer>
  );
};
