import React, { useEffect, useState } from 'react';

import { MosaicCardType } from 'types';
import { Spinner } from 'components/common';

import { MosaicCard } from '../MosaicCard';
import { MosaicCardsContainer } from './styles';

interface Props {
  mosaicCards?: Map<string, MosaicCardType>;
  level: number;
  isUserWon: boolean;
  handleClick: (cardId: string) => void;
}

export const MosaicCards: React.FC<Props> = ({
  level,
  mosaicCards,
  isUserWon,
  handleClick,
}) => {
  const [loadedImages, setLoadedImages] = useState(0);
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);

  const handleImageLoad = () => {
    setLoadedImages((prevLoadedImages) => prevLoadedImages + 1);
  };

  useEffect(() => {
    if (mosaicCards && loadedImages === mosaicCards.size) {
      setIsAllImagesLoaded(true);
    }

    // eslint-disable-next-line
  }, [loadedImages]);

  return (
    <MosaicCardsContainer level={level}>
      {!isAllImagesLoaded && <Spinner />}
      {mosaicCards &&
        Array.from(mosaicCards.entries()).map(([id, mosaicCard]) => (
          <MosaicCard
            key={id}
            cardId={id}
            card={mosaicCard}
            isUserWon={isUserWon}
            isAllImagesLoaded={isAllImagesLoaded}
            handleClick={handleClick}
            handleImageLoad={handleImageLoad}
          />
        ))}
    </MosaicCardsContainer>
  );
};
