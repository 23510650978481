export const AppConfig = {
  LANGUAGES: ['en', 'pl', 'sv'], // Language order matters in fallbacks
  TRANSLATIONS: ['en', 'pl', 'sv'], // Available .json translation files, order matters in fallbacks
  DEFAULT_LANG: 'en',
  MISMATCHED_CARDS_DISPLAY_TIME: 2000, // ms
  MATCHED_CARDS_DISPLAY_TIME: 1000, // ms
  MAX_GAME_TIME: 360, // 6 minutes in s
  MEMORY_CARDS_DISPLAY_TIME: 10 * 1000, // 10 seconds
  FILE_TYPES: 'audio/*, video/*, image/jpeg, image/png, image/webp',
  DATA_FORMATS: {
    CHART_WEEK_FORMAT: 'd MMMM',
    CHART_MONTH_FORMAT: 'MMMM',
    CHART_WEEK_FORMAT_X_AXIS: 'd.MM',
    CHART_MONTH_FORMAT_X_AXIS: 'd',
    STANDARD_FORMAT: 'dd.MM.yyyy',
    API_STRING_FORMAT: 'yyyy-MM-dd',
  },
  DELAY_AFTER_WIN: 2000,
  FORM_DATA_TIMEOUT_REQUEST: 1800000,
  GAME_LEVELS: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  mosaicCardsPerLevel: [20, 30, 35, 48, 56, 63, 72, 80, 88, 96],
  ABOUT_US_REDIRECT: 'https://cogamika.org',
  GA_MEASUREMENT_ID: 'G-JCLS9MJ177',
  META_PIXEL_ID: '1179955179395525',
};
