import styled from 'styled-components';

import { gtMedia } from 'config';
import { ColorType } from 'types';

interface Props {
  bgColor: ColorType;
}

export const StyledMainLayout = styled.div<Props>(
  ({ theme: { colors }, bgColor }) => `
  width: 100%;
  background-color: ${colors[bgColor]};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
);

interface MainCOntainerProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

export const StyledMainContainer = styled.div<MainCOntainerProps>(
  ({ theme, fullWidth, fullHeight }) => `
  width: 100%;
  padding: ${fullWidth ? '0' : '0 24px'};
  display: flex;
  margin: 82px auto 0;
  ${fullHeight ? 'flex: 1' : ''};


  ${gtMedia('sm')} {
    padding: ${fullWidth ? '60px 0' : '60px 72px'};
  }

  ${gtMedia('md')} {
    padding: ${fullWidth ? '60px 0' : '60px 96px'};
  }

  ${gtMedia('lg')} {
    padding: ${fullWidth ? '72px 0' : '72px 144px'};
  }

  ${gtMedia('xl')} {
    padding: ${fullWidth ? '96px 0' : '96px 288px'};
  }

`
);
