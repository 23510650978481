import { FC } from 'react';

import { Flex, Icon } from 'components/common';
import { AccountSubPages, IconName } from 'types';

import { NavItemContainer, NavItemText } from './styles';

export interface NavItem {
  text: string;
  icon: IconName;
  noTranslate?: boolean;
}

interface SidePanelNavProps {
  items: NavItem[];
  activeItem: number;
  onChangePage: (pageIndex: number) => void;
}

export const DesktopNav: FC<SidePanelNavProps> = ({
  items,
  activeItem = AccountSubPages.Results,
  onChangePage,
}) => {
  const renderItems = items.map(({ text, icon, noTranslate }, index) => (
    <NavItemContainer
      active={activeItem === index}
      onClick={() => onChangePage(index)}
      key={index + text}
    >
      <Icon name={icon} />
      <NavItemText
        text={text}
        noTranslate={noTranslate}
        active={activeItem === index}
      />
    </NavItemContainer>
  ));

  return (
    <Flex
      fullWidth
      columnDirection
      alignItems="center"
      gap="sm"
      margin={['lg', '', '', '']}
    >
      {renderItems}
    </Flex>
  );
};
