import React from 'react';
import SVG from 'react-inlinesvg';

import { routes } from 'config';
import { StyledLinkLogo } from './styles';

export const Logo: React.FC = () => {
  return (
    <StyledLinkLogo to={routes.root}>
      <SVG src="/assets/images/cogamika_logo.svg" />
    </StyledLinkLogo>
  );
};
