import { gtMedia } from 'config';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import styled from 'styled-components';

import { CollectionBucketStatus, Theme } from 'types';

interface BuckerContainerProps {
  justify: 'start' | 'end';
}

interface ImageContainerProps {
  status: CollectionBucketStatus;
}

const getVariant = (variant: CollectionBucketStatus, theme: Theme) => {
  switch (variant) {
    case CollectionBucketStatus.Success:
      return `
        background-color: ${theme.colors.success};
      `;
    case CollectionBucketStatus.Failure:
      return `
          background-color: ${theme.colors.error};
        `;
  }
};

export const BucketContainer = styled.div<BuckerContainerProps>(
  ({ theme: { radius }, justify }) => `
  width: 297.5px;
  height: 273px;
  border-radius: ${radius.lg};
  display: flex;
  justify-content: ${justify};
  align-items: center;

  ${gtMedia('md')} {
    width: 404px;
    height: 568px;
  }

  ${gtMedia('lg')} {
    width: 564px;
    height: 800px;
  }

  ${gtMedia('xl')} {
    width: 660px;
    height: 808px;
  }
`
);

interface StyledBucketProps {
  canDrop: boolean;
}

export const ImageContainer = styled('div')<ImageContainerProps>(
  ({ status, theme }) => `
  position: relative;
  height: 100%;

  &::after {
    border-radius: ${theme.radius.md};
    display: ${status === CollectionBucketStatus.None ? 'none' : 'block'};
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    ${getVariant(status, theme)};
  }
`
);

export const StyledImage = styled(LazyLoadImage)<StyledBucketProps>(
  ({ theme: { radius }, canDrop }) => `
    border-radius: ${radius.md};
    object-fit: fill;
    width: 297.5px;
    height: 273px;
    margin: 0 auto;
    filter: ${canDrop ? ' brightness(0.7)' : ''};

    ${gtMedia('md')} {
      width: 404px;
      height: 568px;
    }

    ${gtMedia('lg')} {
      width: 564px;
      height: 800px;
    }

    ${gtMedia('xl')} {
      width: 660px;
      height: 808px;
    }
  `
);
