import {
  UserBaseDataWithoutCredentials,
  UserRole,
  UserStatus,
} from 'cogamika-back/types';
import { Spinner } from 'components/common';
import { routes, userNotPaidRoutes } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { getUserDetails } from 'slices';

interface Props {
  children: React.ReactNode;
  authRoles: UserRole[];
  paidRoles?: UserRole[];
  redirectTo?: string;
}

export const RequireAuthAndPaid: React.FC<Props> = ({
  children,
  authRoles,
  paidRoles,
}) => {
  const { user } = useAppSelector(({ user }) => user);
  const dispatch = useAppDispatch();
  const [inProgress, setProgress] = useState(true);

  const sendRequest = async () => {
    setProgress(true);
    await dispatch(getUserDetails());

    setProgress(false);
  };

  const checkIsUserPaid = (
    user: UserBaseDataWithoutCredentials,
    paidRoles?: UserRole[]
  ) => {
    if (!paidRoles) return true;
    if (user.role && paidRoles.includes(user.role)) return true;
    return user.status === UserStatus.Paid;
  };

  useEffect(() => {
    if (!user) {
      sendRequest();
    } else {
      setProgress(false);
    }

    // eslint-disable-next-line
  }, []);

  if (inProgress) {
    return <Spinner />;
  }

  const isAuthenticated = user?.role && authRoles.includes(user.role);
  const isUserPaid = user && checkIsUserPaid(user, paidRoles);

  const getRedirectPage = () =>
    isAuthenticated
      ? isUserPaid
        ? ''
        : userNotPaidRoutes.account
      : routes.root;

  return (
    <>
      {isAuthenticated && isUserPaid ? (
        children
      ) : (
        <Navigate to={getRedirectPage()} replace />
      )}
    </>
  );
};
