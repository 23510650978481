import React from 'react';

import { apiUrl } from 'config';
import { IconButton } from 'components/common';

import { StyledVideo, MainContainer, Wrapper, ButtonContainer } from './styles';

interface Props {
  instructionFileId: string;
  onClose: () => void;
}

export const InstructionVideo: React.FC<Props> = ({
  onClose,
  instructionFileId,
}) => {
  return (
    <Wrapper>
      <MainContainer>
        <ButtonContainer>
          <IconButton name="cross" onClick={onClose} type="button" />
        </ButtonContainer>
        <StyledVideo
          controls
          src={`${apiUrl}file/${instructionFileId}`}
          disablePictureInPicture
          autoPlay
        ></StyledVideo>
      </MainContainer>
    </Wrapper>
  );
};
