import { Spaces, LayoutSpaces } from 'types';

export const spaces: Spaces = {
  none: '0px',
  '2xs': '4px',
  xs: '8px',
  sm: '12px',
  base: '16px',
  md: '20px',
  lg: '24px',
  xl: '28px',
  '2xl': '32px',
  '3xl': '36px',
  '4xl': '40px',
  '5xl': '44px',
  '6xl': '48px',
  '7xl': '54px',
  '8xl': '58px',
  '9xl': '62px',
  '10xl': '66px',
  '11xl': '70px',
};

export const layoutVerticalSpaces: LayoutSpaces = {
  xs: '32px',
  sm: '60px',
  md: '60px',
  lg: '72px',
  xl: '96px',
};

export const layoutHorizontalSpaces: LayoutSpaces = {
  xs: '24px',
  sm: '72px',
  md: '96px',
  lg: '144px',
  xl: '288px',
};
