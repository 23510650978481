import React from 'react';
import { Text, Flex, FlexAlignCenter, Button } from 'components/common';

import { MainContainer } from './styles';
import { useMediaQuery, useModal } from 'hooks';
import { ConfirmationModalData } from 'types';

export const Confirmation: React.FC = () => {
  const { closeModal, getData } = useModal();
  const { isGtLarge } = useMediaQuery();
  const {
    action,
    text,
    header,
    noTranslate,
    showCloseButton = true,
  } = getData<ConfirmationModalData>();

  return (
    <MainContainer>
      <Flex columnDirection gap={!isGtLarge ? 'base' : '2xl'}>
        <Flex columnDirection gap={!isGtLarge ? '2xs' : '2xl'}>
          {header && (
            <Flex columnDirection>
              <Text
                text={header}
                noTranslate={noTranslate}
                size={!isGtLarge ? 'base' : 'md'}
                weight="semibold"
              />
            </Flex>
          )}
          {text && <Text text={text} noTranslate={noTranslate} size="sm" />}
        </Flex>
        <FlexAlignCenter gap="sm" columnDirection={!isGtLarge} fullWidth>
          <Button
            type="button"
            variant="secondary"
            onClick={action.confirm}
            text={action.text}
            noTranslate={action.noTranslate}
            fullWidth
            uppercase
          />
          {showCloseButton && (
            <Button
              type="button"
              variant="outlined"
              onClick={closeModal}
              text="button.cancel"
              fullWidth
              uppercase
            />
          )}
        </FlexAlignCenter>
      </Flex>
    </MainContainer>
  );
};
